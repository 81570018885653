import { Fragment, useContext, useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import CartDropdown from "../../components/cart-dropdown/cart-dropdown.component.jsx";
import { CartContext } from "../../contexts/cart.context.jsx";
import { useAuth } from "../../contexts/UserProviderMongo.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faShoppingBag,
  faSignOutAlt,
  faUser,
  faUserCircle,
  faReceipt,
  faWarehouse,
  faHistory,
  faChartBar,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import "./navigation.styles.scss";

const Navigation = () => {
  const { isCartOpen } = useContext(CartContext);
  const { user, signout } = useAuth();
  const [menuVisible, setMenuVisible] = useState(false);
  const [userRoleFlag, setUserRoleFlag] = useState(null);

  useEffect(() => {
    setUserRoleFlag(user);
  }, [user]);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  return (
    <Fragment>
      {/* Top Navbar */}
      <div className="top-navbar">
        <div
          className="logo-container"
          onClick={() => (window.location.href = "/")}
        >
          <img
            className="logo"
            src="/JriseAlliancPvtLtdLogo.png"
            alt={user?.logo || "Logo"}
            onError={(e) => {
              e.target.src = "/404.png";
            }}
          />
        </div>

        <div className="menu-icon" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </div>
      </div>

      {/* Right-Side Menu */}
      <div className={`right-menu ${menuVisible ? "visible" : ""}`}>
        <button className="menu-close-button" onClick={closeMenu}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <Nav className="menu-nav">
          <Nav.Link
            as={Link}
            to="/ShaktiProducts"
            onClick={closeMenu}
            className="nav-link"
            style={{ color: "white" }}
          >
            <FontAwesomeIcon
              icon={faReceipt}
              style={{ color: "white" }}
              className="menu-icon"
            />{" "}
            Shakti Products
          </Nav.Link>
          <Nav.Link as={Link} to="/VisionShakti" onClick={closeMenu}>
            <FontAwesomeIcon
              icon={faChartBar}
              style={{ color: "white" }}
              className="menu-icon"
            />{" "}
            VisionShakti
          </Nav.Link>
          {userRoleFlag && (
            <>
              {userRoleFlag?.isAdmin && (
                <Nav.Link
                  as={Link}
                  to="/invoice-generate"
                  onClick={closeMenu}
                  className="nav-link"
                >
                  <FontAwesomeIcon
                    icon={faReceipt}
                    style={{ color: "white" }}
                    className="menu-icon"
                  />{" "}
                  Invoice Generation
                </Nav.Link>
              )}
              {userRoleFlag?.isWarehouseManager && (
                <Nav.Link
                  as={Link}
                  to="/warehouse"
                  onClick={closeMenu}
                  className="nav-link"
                >
                  <FontAwesomeIcon
                    icon={faWarehouse}
                    style={{ color: "white" }}
                    className="menu-icon"
                  />{" "}
                  Warehouse
                </Nav.Link>
              )}
              <Nav.Link
                as={Link}
                to="/profile"
                onClick={closeMenu}
                className="nav-link"
              >
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="menu-icon"
                  style={{ color: "white" }}
                />{" "}
                Profile
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/OrderHistory"
                onClick={closeMenu}
                className="nav-link"
              >
                <FontAwesomeIcon
                  icon={faHistory}
                  className="menu-icon"
                  style={{ color: "white" }}
                />{" "}
                Order History
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/ShaktiAdmin"
                onClick={closeMenu}
                className="nav-link"
              >
                <FontAwesomeIcon
                  icon={faUser}
                  className="menu-icon"
                  style={{ color: "white" }}
                />{" "}
                Shakti Admin Panel
              </Nav.Link>
              <Nav.Link onClick={signout} className="nav-link">
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  className="menu-icon"
                  style={{ color: "white" }}
                />{" "}
                Sign Out
              </Nav.Link>
            </>
          )}

          {!userRoleFlag && (
            <Nav.Link
              as={Link}
              to="/SignUp"
              onClick={closeMenu}
              className="nav-link"
            >
              <FontAwesomeIcon icon={faUserPlus} className="menu-icon" /> Sign
              Up
            </Nav.Link>
          )}
        </Nav>
      </div>

      {isCartOpen && <CartDropdown />}
      <Outlet />
    </Fragment>
  );
};

export default Navigation;
