import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditShaktiProduct = ({
  isOpen,
  onRequestClose,
  productId,
  productData,
  fetchProducts,
}) => {
  const [formData, setFormData] = useState({
    MRP: "",
    CustomerPrice: "",
    OfferPriceToBC: "",
    MarginValueToBC: "",
    marginValueShakti: "",
    description: "",
    image: null,
  });

  // Populate the form with the existing product data
  useEffect(() => {
    if (productData) {
      setFormData({
        MRP: productData.MRP || "",
        CustomerPrice: productData.CustomerPrice || "",
        OfferPriceToBC: productData.OfferPriceToBC || "",
        MarginValueToBC: productData.MarginValueToBC || "",
        marginValueShakti: productData.marginValueShakti || "",
        description: productData.description || "",
        image: null, // Keep image empty for updates
      });
    }
  }, [productData]);

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      image: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedData = new FormData();
      updatedData.append("MRP", formData.MRP);
      updatedData.append("CustomerPrice", formData.CustomerPrice);
      updatedData.append("OfferPriceToBC", formData.OfferPriceToBC);
      updatedData.append("MarginValueToBC", formData.MarginValueToBC);
      updatedData.append("marginValueShakti", formData.marginValueShakti);
      updatedData.append("description", formData.description);

      if (formData.image) {
        updatedData.append("image", formData.image);
      }

      const response = await axios.patch(
        `http://localhost:3002/products/updateShaktiProduct/${productId}/159`,
        updatedData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Product updated successfully!");
      onRequestClose();
      fetchProducts(); // Refresh the product list
    } catch (error) {
      console.error("Failed to update the product:", error);
      toast.error("Failed to update the product.");
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        right: isOpen ? 0 : "-400px", // Adjust for slide-in effect
        height: "100%",
        width: "400px",
        backgroundColor: "#fff",
        boxShadow: "-2px 0 5px rgba(0, 0, 0, 0.1)",
        transition: "right 0.3s ease-in-out",
        zIndex: 1000,
        padding: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          borderBottom: "1px solid #ddd",
          paddingBottom: "10px",
        }}
      >
        <h2 style={{ margin: 0, color: "#333" }}>Edit Product</h2>
        <button
          onClick={onRequestClose}
          style={{
            background: "none",
            border: "none",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
        >
          &times;
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ fontWeight: "bold", display: "block" }}>MRP</label>
          <input
            type="number"
            name="MRP"
            value={formData.MRP}
            onChange={handleChange}
            required
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ fontWeight: "bold", display: "block" }}>
            Customer Price
          </label>
          <input
            type="number"
            name="CustomerPrice"
            value={formData.CustomerPrice}
            onChange={handleChange}
            required
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ fontWeight: "bold", display: "block" }}>
            Offer Price to BC
          </label>
          <input
            type="number"
            name="OfferPriceToBC"
            value={formData.OfferPriceToBC}
            onChange={handleChange}
            required
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ fontWeight: "bold", display: "block" }}>
            Margin Value to BC
          </label>
          <input
            type="text"
            name="MarginValueToBC"
            value={formData.MarginValueToBC}
            onChange={handleChange}
            required
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ fontWeight: "bold", display: "block" }}>
            Margin Value Shakti
          </label>
          <input
            type="number"
            name="marginValueShakti"
            value={formData.marginValueShakti}
            onChange={handleChange}
            required
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ fontWeight: "bold", display: "block" }}>
            Description
          </label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              minHeight: "80px",
            }}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ fontWeight: "bold", display: "block" }}>
            Product Image
          </label>
          <input type="file" name="image" onChange={handleFileChange} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <button
            type="button"
            onClick={onRequestClose}
            style={{
              padding: "10px 20px",
              background: "#f5f5f5",
              border: "1px solid #ccc",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              background: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditShaktiProduct;
