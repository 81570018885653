import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import Spinner from "react-bootstrap/Spinner";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../../../contexts/UserProviderMongo.jsx";
import PaginationComponent from "../../../pagination/PaginationComponent.jsx";
import LoadingOverlay from "../../../user-profile/order-history/LoadingOverlay.jsx";

function PurchaseOrderHistory() {
  const files = useAuth([]);
  console.log(
    "🚀 ~ file: order-history.jsx:17 ~ PurchaseOrderHistory ~ files:",
    files
  );
  const { generatePurchaseOrder } = useAuth([]);
  const { getPurchaseOrderForm } = useAuth([]);
  console.log(
    "🚀 ~ file: order-history.jsx:20 ~ PurchaseOrderHistory ~ getPurchaseOrderForm:",
    getPurchaseOrderForm
  );
  const { handleSoftDelete } = useAuth([]);

  const { getRefreshPurchaseOrder, orderDownload, refreshFilesFormOrder } =
    useAuth([]); // Get data and function from context

  const pageSize = 10; // Number of rows per page
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageForm, setCurrentPageForm] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [deleteFile, setDeleteFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [excelOrderForms, setExcelOrderForms] = useState([]); //for sales order form
  const [orderForms, setOrderForms] = useState([]); //for sales order form
  console.log(
    "🚀 ~ file: order-history.jsx:33 ~ PurchaseOrderHistory ~ orderForms:",
    orderForms
  );

  //ANCHOR  <!---- For Sale Order form ----!>
  useEffect(() => {
    const getSaleOrderAllFormMap = getPurchaseOrderForm?.data;

    if (getSaleOrderAllFormMap) {
      // If getSaleOrderAllFormMap is not null or undefined, set it in the state
      setOrderForms(getSaleOrderAllFormMap);
    }
  }, [getPurchaseOrderForm?.data]);

  //ANCHOR <!---- For Excel Order ----!>
  useEffect(() => {
    const ExcelOrderFormsMap = files?.files;
    console.log("🚀 ~ file: order-history.jsx:57 ~ useEffect ~ files:", files);

    if (ExcelOrderFormsMap) {
      // If getSaleOrderAllFormMap is not null or undefined, set it in the state
      setExcelOrderForms(ExcelOrderFormsMap);
    }
  }, [files]);

  const handleEditClick = (file) => {
    setEditFile(file);
    setShowEditModal(true);
  };

  // const handleDeleteClick = async (file) => {
  //   await handleSoftDelete();
  // };

  const handleDeleteClick = (file) => {
    setDeleteFile(file);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async (file) => {
    // e.preventDefault();
    const uid = file;

    try {
      // console.log(formData);
      await handleSoftDelete(uid);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelDelete = () => {
    // Cancel the delete operation and close the modal
    setIsDeleteModalOpen(false);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      await getRefreshPurchaseOrder();
      // await refreshFilesFormOrder();
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleRefresh();
  });

  if (isLoading) {
    return (
      <div>
        <h1>User Files</h1>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  //ANCHOR  <!---- Form Order to check if files ?? [] ----!>
  if (!orderForms || orderForms.length === 0) {
    return (
      <div>
        <h1>No Form Order Sales founds</h1>
        <Button onClick={handleRefresh}>Refresh Data</Button>{" "}
      </div>
    );
  }

  //ANCHOR  <!---- Excel Order to check if files ?? [] ----!>
  // if (!excelOrderForms || !files?.files || files.files?.length === 0) {
  console.log(
    "🚀 ~ file: order-history.jsx:142 ~ PurchaseOrderHistory ~ files:",
    files
  );
  console.log(
    "🚀 ~ file: order-history.jsx:142 ~ PurchaseOrderHistory ~ excelOrderForms:",
    excelOrderForms
  );
  //   return (
  //     <div>
  //       <h1>No Form Order Sales </h1>
  //       <Button onClick={handleRefresh}>Refresh Data</Button>{" "}
  //     </div>
  //   );
  // }

  //ANCHOR  <!---- For Excel Upload Order ----!>
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, excelOrderForms.length);
  // const excelUploadOrder = excelOrderForms.slice(startIndex, endIndex);
  const excelUploadOrder = Array.isArray(excelOrderForms)
    ? excelOrderForms.slice(startIndex, endIndex)
    : [];
  const totalPages = Math.ceil(excelOrderForms.length / pageSize);

  //ANCHOR  <!---- For Form Order ----!>
  const startIndexForm = (currentPageForm - 1) * pageSize;
  const endIndexForm = Math.min(startIndexForm + pageSize, orderForms?.length);
  const formUploadOrder = Array.isArray(orderForms)
    ? orderForms?.slice(startIndexForm, endIndexForm)
    : [];
  const totalPagesForm = Math.ceil(orderForms?.length / pageSize);
  console.log(formUploadOrder, "formUploadOrder");
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setCurrentPageForm(page);
  };
  const handlePageChangeForm = (page) => {
    setCurrentPageForm(page);
  };

  const handleDownload = async (file) => {
    const fileId = file._id;
    console.log(
      "🚀 ~ file: order-history.jsx:174 ~ handleDownload ~ fileId:",
      fileId
    );
    // Handle download logic here, using file._id or other data
    await orderDownload(fileId); // Example usage, replace with your actual download logic
  };

  const handleGenerateInvoice = (file) => {
    const fileId = file._id;
    console.log(
      "🚀 ~ file: order-history.jsx:180 ~ handleGenerateInvoice ~ fileId:",
      fileId
    );

    // Handle generate invoice logic here, using file._id or other data
    generatePurchaseOrder(fileId); // Example usage, replace with your actual generate invoice logic
  };

  return (
    <div className="order-history-body">
      <div className="form-upload-order-container">
        {" "}
        <h1>Order History</h1>
        <Button className="order-history-Refresh" onClick={handleRefresh}>
          Refresh Data
        </Button>
        <Table striped bordered hover className="custom-table">
          <thead>
            <tr>
              <th>Invoice Date</th>
              <th> Bill To Address</th>
              <th> Bill To GST</th>
              <th> Ship To Name</th>
              <th> Payment Terms</th>
              <th>Edit</th>
              <th>Delete</th>
              <th>Download Tax Invoice</th>
            </tr>
          </thead>
          <tbody>
            {formUploadOrder.map((file) => (
              <tr key={file._id}>
                <td>{file?.invoiceDate || "-"}</td>
                <td>{file?.billTo?.address || "-"}</td>
                <td>{file?.billTo?.taxId || "-"}</td>
                <td>{file?.shipTo?.[0].address || "-"}</td>
                <td>{file?.PaymentTerms || "-"}</td>
                <td>
                  <button
                    disabled
                    onClick={() => handleEditClick(file)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <EditIcon color="disabled" />
                    {/* <EditIcon color="primary" color="disabled" /> */}
                  </button>
                </td>
                <td>
                  <button
                    disabled
                    onClick={() => handleDeleteClick(file._id)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <DeleteIcon color="disabled" />
                    {/* <DeleteIcon color="error" color="disabled" /> */}
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => handleGenerateInvoice(file)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <ReceiptIcon color="primary" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <PaginationComponent
          currentPage={currentPageForm}
          totalPages={totalPagesForm}
          onPageChange={handlePageChangeForm}
        />
        {isLoading && <LoadingOverlay />}
      </div>
      {/* {showEditModal && (
        <EditOrderForm
          isOpen={showEditModal}
          onRequestClose={handleEditModalClose}
          file={editFile}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onRequestClose={handleCancelDelete}
          onConfirmDelete={handleConfirmDelete}
          file={deleteFile}
        />
      )} */}
    </div>
  );
}

export default PurchaseOrderHistory;
