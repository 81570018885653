import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import UploadIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";
import PaginationComponent from "../../pagination/PaginationComponent.jsx";
import { useDeliveryChallanMongo } from "../../../contexts/deliveryChallanContext.jsx";
import DeleteConfirmationModal from "../../success-modal/deleteModal.js";
import CreateVendorCustomerModal from "./CreateVendorCustomerModal.js";
import EditVendorCustomerModal from "./EditVendorCustomerModal.js";
import axios from "axios";
import { BaseUrl } from "../../../api/user.api.js";
import "./CreateVendorCustomerModal.css";
import { toast } from "react-toastify";

function vendorCustomerTable() {
  const { getVendorCustomerDataTable } = useDeliveryChallanMongo([]);
  const { deleteVendorCustomerContext } = useDeliveryChallanMongo([]);
  const { refereshGetVendorCustomerData } = useDeliveryChallanMongo([]);

  const pageSize = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [deleteFile, setDeleteFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [orderForms, setOrderForms] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [selectedFile, setSelectedFile] = useState(null); // For file upload
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageForm, setCurrentPageForm] = useState(1);

  useEffect(() => {
    const getSaleOrderAllFormMap = getVendorCustomerDataTable?.users;
    if (getSaleOrderAllFormMap) {
      setOrderForms(getSaleOrderAllFormMap);
    }
  }, [getVendorCustomerDataTable?.users]);

  const handleDeleteClick = async (file) => {
    setDeleteFile(file);
    setIsDeleteModalOpen(true);
    await deleteVendorCustomerContext(file);
  };

  const handleCancelDelete = () => setIsDeleteModalOpen(false);

  const handleEditModalClose = () => setShowEditModal(false);

  const handleOpenModal = () => setShowCreateModal(true);

  const handleCloseModal = () => setShowCreateModal(false);

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      await refereshGetVendorCustomerData();
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = (file) => {
    setEditFile(file);
    setShowEditModal(true);
  };

  const handleSearchChange = (e) =>
    setSearchQuery(e.target.value.toLowerCase());

  const handleRoleFilterChange = (e) => setRoleFilter(e.target.value);

  //ANCHOR  <!---- For Form Order ----!>
  const startIndexForm = (currentPageForm - 1) * pageSize;
  const endIndexForm = Math.min(startIndexForm + pageSize, orderForms?.length);
  const formUploadOrder = Array.isArray(orderForms)
    ? orderForms?.slice(startIndexForm, endIndexForm)
    : [];
  const totalPagesForm = Math.ceil(orderForms?.length / pageSize);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setCurrentPageForm(page);
  };
  const handlePageChangeForm = (page) => {
    setCurrentPageForm(page);
  };

  const handleDownloadTemplate = async () => {
    try {
      const response = await axios({
        url: `${BaseUrl}/vendor-customer/downloadExcelTemplate`,
        method: "GET",
        responseType: "blob", // Important to set the response type as blob
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "VendorCustomerBulkTemplate.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the template:", error);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile); // key: file, value: the selected file

    try {
      const response = await axios.post(
        `${BaseUrl}/vendor-customer/bulk-create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("File uploaded successfully!");
      setSelectedFile(null);
      handleRefresh(); // Refresh data after bulk creation
    } catch (error) {
      console.error("Error uploading file:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error("An unexpected error occurred while uploading the file.");
      }
    }
  };
  // Sort the formUploadOrder array by the invoiceDate in descending order
  const sortedFormUploadOrder = formUploadOrder?.slice().sort((a, b) => {
    const dateA = new Date(a.invoiceDate);
    const dateB = new Date(b.invoiceDate);
    return dateB - dateA;
  });

  if (isLoading) {
    return (
      <div>
        <h1>Loading Data...</h1>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  const handleNewCustomer = (newCustomer) => {
    setCustomers((prevCustomers) => [newCustomer, ...prevCustomers]);
  };

  // ** Filter Logic **
  const filteredData = orderForms.filter((file) => {
    const searchMatch =
      file.name?.toLowerCase().includes(searchQuery) ||
      file.email?.toLowerCase().includes(searchQuery) ||
      file.phone?.toLowerCase().includes(searchQuery) ||
      file.companyName?.toLowerCase().includes(searchQuery);

    const roleMatch = roleFilter ? file.role === roleFilter : true;

    return searchMatch && roleMatch;
  });

  // ** Pagination Logic **
  const paginatedData = filteredData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  return (
    <div className="order-history-body" style={{ padding: "0px" }}>
      <div className="form-upload-order-container">
        <h1 style={{ marginBottom: "20px" }}>Vendor And Customer Portal</h1>

        {/* Navigation Bar */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginBottom: "20px",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              variant="primary"
              onClick={() => setShowCreateModal(true)}
              style={buttonStyle}
            >
              <AddIcon fontSize="small" /> Add New
            </Button>

            <Button
              variant="outline-primary"
              onClick={handleDownloadTemplate}
              style={buttonStyle}
            >
              <DownloadIcon fontSize="small" /> Download Template
            </Button>

            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {/* Select File Button */}
              <label htmlFor="file-upload" style={uploadLabelStyle}>
                <UploadIcon fontSize="small" />
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                style={hiddenInputStyle} // Hides the input field but it still works
              />

              {selectedFile && (
                <span style={{ fontSize: "11px", fontWeight: "bold" }}>
                  {selectedFile.name}
                </span>
              )}

              {/* Upload Button */}
              <Button
                variant="primary"
                onClick={handleFileUpload}
                disabled={!selectedFile} // Button is disabled if no file is selected
                style={buttonStyle}
              >
                Upload File
              </Button>
            </div>
          </div>

          <div style={{ display: "flex", gap: "10px", marginTop: "1px" }}>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />

            <select value={roleFilter} onChange={handleRoleFilterChange}>
              <option value="">All Roles</option>
              <option value="vendor">Vendor</option>
              <option value="customer">Customer</option>
            </select>
          </div>
        </div>

        {/* Table */}
        <div className="table-responsive">
          <Table striped bordered hover className="custom-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Role</th>
                <th>Company Name</th>
                <th>GST Number</th>
                <th>GST Status</th>
                <th>Created At</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((file) => (
                <tr key={file._id}>
                  <td>{file?.name || "-"}</td>
                  <td>{file?.email || "-"}</td>
                  <td>{file?.phone || "-"}</td>
                  <td>{file?.role || "-"}</td>
                  <td>{file?.companyName || "-"}</td>
                  <td>{file?.gstNumber || "-"}</td>
                  <td>{file?.gstStatus || "-"}</td>
                  <td>{file?.createdAt || "-"}</td>
                  <td>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="outline-primary"
                        onClick={() => handleEditClick(file)}
                        style={actionButtonStyle}
                      >
                        <EditIcon fontSize="small" />
                      </Button>
                    </div>
                  </td>
                  <td>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="outline-danger"
                        onClick={() => handleDeleteClick(file._id)}
                        style={actionButtonStyle}
                      >
                        <DeleteIcon fontSize="small" />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {isLoading && <LoadingOverlay />}

        {showCreateModal && (
          <CreateVendorCustomerModal
            isOpen={showCreateModal}
            onRequestClose={handleCloseModal}
            onSuccess={handleNewCustomer}
          />
        )}
        {showEditModal && (
          <EditVendorCustomerModal
            isOpen={showEditModal}
            customerId={editFile?._id}
            onRequestClose={handleEditModalClose}
            initialData={editFile}
          />
        )}
        {isDeleteModalOpen && (
          <DeleteConfirmationModal
            isOpen={isDeleteModalOpen}
            onRequestClose={handleCancelDelete}
            onConfirmDelete={handleConfirmDelete}
            file={deleteFile}
          />
        )}
        <PaginationComponent
          currentPage={currentPage}
          totalPages={Math.ceil(filteredData.length / pageSize)}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

const buttonStyle = {
  display: "flex",
  alignItems: "center",
  gap: "5px",
  // padding: "8px 12px",
  fontSize: "12px",
  borderRadius: "5px",
};

const actionButtonStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "4px 8px",
  fontSize: "12px",
  borderRadius: "5px",
};

const searchStyle = {
  padding: "8px 12px",
  width: "250px",
  borderRadius: "5px",
  border: "1px solid #ccc",
};

const filterStyle = {
  padding: "8px 12px",
  width: "150px",
  borderRadius: "5px",
  border: "1px solid #ccc",
};

const uploadLabelStyle = {
  display: "flex",
  alignItems: "center",
  gap: "5px",
  cursor: "pointer",
  padding: "8px 12px",
  fontSize: "12px",
  border: "1px solid #007bff",
  borderRadius: "5px",
  color: "#007bff",
};

const hiddenInputStyle = {
  position: "absolute",
  width: "1px",
  height: "1px",
  padding: "0",
  margin: "-1px",
  overflow: "hidden",
  clip: "rect(0,0,0,0)",
  border: "0",
};
export default vendorCustomerTable;
