import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import SubmitButton from "../../bulk-order/submit.btn.component.jsx";
import axios from "axios";
import { BaseUrl } from "../../../api/user.api.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    borderRadius: "10px",
    width: "500px",
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
  },
};

Modal.setAppElement("#root");

function EditVendorCustomerModal({
  isOpen,
  onRequestClose,
  customerId,
  initialData,
}) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    role: "vendor",
    companyName: "",
    gstNumber: "",
    gstStatus: "Regular",
    additionalInfo: {
      PAN: "",
    },
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name.includes("additionalInfo.")) {
      // Handle nested property (like additionalInfo.PAN)
      const property = name.split(".")[1];
      setFormData((prevData) => ({
        ...prevData,
        additionalInfo: {
          ...prevData.additionalInfo,
          [property]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSave = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };

      console.log("🚀 ~ handleSave ~ customerId:", customerId);

      const response = await axios.put(
        `${BaseUrl}/vendor-customer/updateVendorCustomer/${customerId}`,
        formData,
        { headers }
      );

      console.log("Vendor/Customer updated successfully:", response.data);

      // Show success toast
      toast.success("Vendor/Customer updated successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      onRequestClose(); // Close the modal after successful submission
    } catch (error) {
      console.error("Error updating vendor/customer:", error);

      const message =
        error?.response?.data?.message ||
        "An error occurred while updating the vendor/customer.";

      // Show error toast
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
        contentLabel="Edit Vendor/Customer"
      >
        <h2 className="text-center">Edit Vendor/Customer</h2>
        <button
          onClick={onRequestClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          <CloseIcon />
        </button>

        <form>
          <div className="form-section my-3">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-section my-3">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-section my-3">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              name="phone"
              placeholder="Enter phone"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-section my-3">
            <label htmlFor="role">Role</label>
            <select
              name="role"
              value={formData.role}
              onChange={handleInputChange}
            >
              <option value="vendor">Vendor</option>
              <option value="customer">Customer</option>
            </select>
          </div>

          <div className="form-section my-3">
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              name="companyName"
              placeholder="Enter company name"
              value={formData.companyName}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-section my-3">
            <label htmlFor="gstNumber">GST Number</label>
            <input
              type="text"
              name="gstNumber"
              placeholder="Enter GST Number"
              value={formData.gstNumber}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-section my-3">
            <label htmlFor="gstStatus">GST Status</label>
            <select
              name="gstStatus"
              value={formData.gstStatus}
              onChange={handleInputChange}
            >
              <option value="Regular">Regular</option>
              <option value="Composition">Composition</option>
              <option value="Unknown">Unknown</option>
            </select>
          </div>

          <div className="form-section my-3">
            <label htmlFor="additionalInfo.PAN">PAN</label>
            <input
              type="text"
              name="additionalInfo.PAN"
              placeholder="Enter PAN"
              value={formData.additionalInfo.PAN}
              onChange={handleInputChange}
            />
          </div>
        </form>

        <SubmitButton handleSubmit={handleSave} />
      </Modal>

      {/* Toastify Container */}
      <ToastContainer />
    </>
  );
}

export default EditVendorCustomerModal;
