import React, { useState } from "react";
import { BaseUrl } from "../../../api/user.api.js";

function InvoiceActions() {
  // States for updating credit note status
  const [formData, setFormData] = useState({
    invoiceNumber: "",
    newStatus: "Cancelled", // Default status
    creditNoteDate: "",
  });
  const [responseMessageStatus, setResponseMessageStatus] = useState(null);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);

  // States for downloading invoice PDF
  const [invoiceDownloadNumber, setInvoiceDownloadNumber] = useState("");
  const [responseMessageDownload, setResponseMessageDownload] = useState(null);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  // States for fetching cancelled invoices
  const [fromDate, setFromDate] = useState("2024-11-01");
  const [toDate, setToDate] = useState("2024-11-30");
  const [cancelledInvoices, setCancelledInvoices] = useState([]);
  console.log("🚀 ~ InvoiceActions ~ cancelledInvoices:", cancelledInvoices);
  const [responseMessageFetch, setResponseMessageFetch] = useState(null);
  const [isLoadingFetch, setIsLoadingFetch] = useState(false);

  // Handle form input changes for credit note status update
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle invoice number change for download
  const handleInvoiceNumberChange = (e) => {
    setInvoiceDownloadNumber(e.target.value);
  };

  // Handle date change for fetching cancelled invoices
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "fromDate") setFromDate(value);
    if (name === "toDate") setToDate(value);
  };

  // Handle form submission for updating credit note status
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingStatus(true);
    setResponseMessageStatus(null);

    try {
      const response = await fetch(
        `${BaseUrl}/tax-invoices/updateCreditNoteStatusMidland`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setResponseMessageStatus(`Success: ${JSON.stringify(data?.message)}`);
      } else {
        const errorText = await response.text();
        setResponseMessageStatus(`Error: ${errorText}`);
      }
    } catch (error) {
      setResponseMessageStatus(`Error: ${error.message}`);
    } finally {
      setIsLoadingStatus(false);
    }
  };

  // Handle invoice PDF download
  const handleDownloadInvoice = async (e) => {
    e.preventDefault();
    if (!invoiceDownloadNumber) {
      setResponseMessageDownload("Please enter an invoice number to download.");
      return;
    }

    setIsLoadingDownload(true);
    setResponseMessageDownload(null);

    try {
      const response = await fetch(
        `http://localhost:3001/tax-invoices/downloadInvoicePDF?invoiceNumber=${invoiceDownloadNumber}`,
        {
          method: "GET",
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${invoiceDownloadNumber}.pdf`;
        link.click();
        setResponseMessageDownload(
          `Invoice ${invoiceDownloadNumber} downloaded successfully.`
        );
      } else {
        const errorText = await response.text();
        setResponseMessageDownload(`Error: ${errorText}`);
      }
    } catch (error) {
      setResponseMessageDownload(`Error: ${error.message}`);
    } finally {
      setIsLoadingDownload(false);
    }
  };

  // Fetch Cancelled Invoices based on date range
  const handleFetchCancelledInvoices = async (e) => {
    e.preventDefault();
    setIsLoadingFetch(true);
    setResponseMessageFetch(null);

    try {
      const response = await fetch(
        `${BaseUrl}/tax-invoices/getCancelledInvoicesForMidland`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fromDate,
            toDate,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("🚀 ~ handleFetchCancelledInvoices ~ data:", data);
        setCancelledInvoices(data);
        setResponseMessageFetch(
          `Fetched cancelled invoices: ${data?.count} found`
        );
      } else {
        const errorText = await response.text();
        setResponseMessageFetch(`Error: ${errorText}`);
      }
    } catch (error) {
      setResponseMessageFetch(`Error: ${error.message}`);
    } finally {
      setIsLoadingFetch(false);
    }
  };

  return (
    <div style={styles.container}>
      {/* Update Credit Note Status Card */}
      <div style={styles.card}>
        <h2>Cancel the invoice</h2>
        <h6>Use full invoice number</h6>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.formGroup}>
            <label style={styles.label} htmlFor="invoiceNumber">
              Invoice Number:
            </label>
            <input
              type="text"
              id="invoiceNumber"
              name="invoiceNumber"
              value={formData.invoiceNumber}
              onChange={handleChange}
              style={styles.input}
              placeholder="Enter Invoice Number"
              required
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label} htmlFor="newStatus">
              New Status:
            </label>
            <select
              id="newStatus"
              name="newStatus"
              value={formData.newStatus}
              onChange={handleChange}
              style={styles.select}
            >
              <option value="Cancelled">Cancelled</option>
              <option value="Active">Active</option>
              <option value="Pending">Pending</option>
            </select>
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label} htmlFor="creditNoteDate">
              Credit Note Date:
            </label>
            <input
              type="date"
              id="creditNoteDate"
              name="creditNoteDate"
              value={formData.creditNoteDate}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>
          <button
            type="submit"
            style={styles.button}
            disabled={isLoadingStatus}
          >
            {isLoadingStatus ? "Updating..." : "Update Status"}
          </button>
        </form>
        {responseMessageStatus && (
          <div style={styles.responseMessage}>
            <p>{responseMessageStatus}</p>
          </div>
        )}
      </div>

      {/* Download Invoice PDF Card */}
      <div style={styles.card}>
        <h2>Download Invoice PDF</h2>
        <form onSubmit={handleDownloadInvoice} style={styles.form}>
          <div style={styles.formGroup}>
            <label style={styles.label} htmlFor="invoiceDownloadNumber">
              Invoice Number:
            </label>
            <input
              type="text"
              id="invoiceDownloadNumber"
              value={invoiceDownloadNumber}
              onChange={handleInvoiceNumberChange}
              style={styles.input}
              placeholder="Enter Invoice Number"
              required
            />
          </div>
          <button
            type="submit"
            style={styles.button}
            disabled={isLoadingDownload}
          >
            {isLoadingDownload ? "Downloading..." : "Download PDF"}
          </button>
        </form>
        {responseMessageDownload && (
          <div style={styles.responseMessage}>
            <p>{responseMessageDownload}</p>
          </div>
        )}
      </div>

      {/* Fetch Cancelled Invoices Card */}
      <div style={styles.card}>
        <h2>Fetch Cancelled Invoices</h2>
        <form onSubmit={handleFetchCancelledInvoices} style={styles.form}>
          <div style={styles.formGroup}>
            <label style={styles.label} htmlFor="fromDate">
              From Date:
            </label>
            <input
              type="date"
              id="fromDate"
              name="fromDate"
              value={fromDate}
              onChange={handleDateChange}
              style={styles.input}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label} htmlFor="toDate">
              To Date:
            </label>
            <input
              type="date"
              id="toDate"
              name="toDate"
              value={toDate}
              onChange={handleDateChange}
              style={styles.input}
            />
          </div>
          <button type="submit" style={styles.button} disabled={isLoadingFetch}>
            {isLoadingFetch ? "Fetching..." : "Fetch Cancelled Invoices"}
          </button>
        </form>
        {responseMessageFetch && (
          <div style={styles.responseMessage}>
            <p>{responseMessageFetch}</p>
          </div>
        )}
        {cancelledInvoices?.cancelledInvoices?.length > 0 && (
          <div style={styles.circle}>
            <p style={styles.circleText}>{cancelledInvoices?.count}</p>
          </div>
        )}
        {cancelledInvoices?.cancelledInvoices?.length > 0 && (
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>Invoice Number</th>
                <th style={styles.tableHeader}>Date for Cancellation</th>
              </tr>
            </thead>
            <tbody>
              {cancelledInvoices?.cancelledInvoices?.map((invoice, index) => {
                console.log("Invoice Data: ", invoice); // Log each invoice
                return (
                  <tr key={index}>
                    <td style={styles.tableCell}>
                      {invoice?.shipTo[0]?.invoiceNumber || "N/A"}
                    </td>
                    <td style={styles.tableCell}>
                      {invoice?.shipTo[0]?.invoiceDateForCancelled || "N/A"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    maxWidth: "1000px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    height: "100vh !important",
    // backgroundColor: "white",
  },
  card: {
    width: "48%",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "8px",
    fontWeight: "bold",
    fontSize: "14px",
  },
  input: {
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "14px",
  },
  select: {
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "14px",
  },
  button: {
    padding: "12px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
  },
  responseMessage: {
    marginTop: "20px",
    padding: "10px",
    borderRadius: "4px",
    backgroundColor: "#e8f5e9",
    color: "#2e7d32",
  },
  circle: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "#007bff",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px auto",
  },
  circleText: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  },
  tableHeader: {
    padding: "10px",
    backgroundColor: "#f1f1f1",
    border: "1px solid #ccc",
    textAlign: "left",
  },
  tableCell: {
    padding: "10px",
    border: "1px solid #ccc",
  },
};

export default InvoiceActions;
