import axios from "axios";
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { toast } from "react-toastify";
import {
  DeliveryChallanContextRoutes,
  GenerateInvoiceRoutes,
  GenerateDeliveryChallanRoutes,
  GetSalesOrderRoutes,
  OrderHistoryContextRoutes,
  RefreshFilesDeliveryChallanRoutes,
  RefreshFilesFormOrderRoutes,
  updateDeliveryChallanRoutes,
  updateTaxInvoiceApi,
  createDeliveryChallanRoute,
  GetExcelDeliveryChallanRoutes,
  DeleteDeliveryChallanRoutes,
  createCustomerInvoicesRoute,
  GetCustomerInvoicesRoutes,
  getCustomerInvoicesRoutes,
  getCustomerInvoicesPdfRoutes,
  getGeneralTaxInvoiceRoutes,
  getGeneralTaxInvoicePdfRoutes,
  BaseUrl,
} from "../api/user.api.js";
import { useAuth } from "./UserProviderMongo.jsx";
import { useNavigate } from "react-router-dom";

const DeliveryChallanMongo = createContext();

const DeliveryChallanMongoContext = ({ children }) => {
  const navigate = useNavigate();

  const { user } = useAuth([]);

  const [userId, setUserId] = useState(null);
  const [error, setError] = useState(null);
  const [CreateDeliveryChallanState, setCreateDeliveryChallanState] = useState(
    []
  ); // create delivery challan
  const [filesDeliveryChallan, setFilesDeliveryChallan] = useState([]);
  const [getDeliveryChallanForm, setGetDeliveryChallanForm] = useState([]);
  const [updatedDeliveryChallanData, setUpdatedDeliveryChallanData] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  //ANCHOR - DELIVERY CHALLAN USESTATE
  const [getDeliveryChallan, SetGetDeliveryChallan] = useState([]);
  // ANCHOR - DELIVERY CHALLAN XLSX FILE TEMPLATE
  const [getDeliveryChallanXlsxTemplate, setGetDeliveryChallanXlsxTemplate] =
    useState([]);
  //ANCHOR - Delete usestate
  const [deleteOrderForms, setDeleteOrderForms] = useState([]);
  const [deleteVendorUser, setDeleteVendorUser] = useState([]);

  //ANCHOR - CUSTOMER INVOICES MIDLAND
  const [CreateCustomerInvoicesState, setCreateCustomerInvoicesState] =
    useState([]); // create delivery challan
  const [getCustomerInvoices, setCustomerInvoices] = useState([]);

  // GENERAL TAX INVOICE
  const [getGeneralTaxInvoice, setGetGeneralTaxInvoice] = useState([]);
  const [getVendorCustomerDataTable, setGetVendorCustomerDataTable] = useState(
    []
  );

  useEffect(() => {
    // Set userId state if user object is available
    if (user) {
      setUserId(user.userId);
    }
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        await getSalesOrder(userId);
        await getDeliveryChallanContext(userId);
        await getCustomerInvoicesContext(userId);
        await getGeneralTaxInvoiceContext(userId);
        await getVendorCustomerData(userId);
      } else {
        // navigate("/");
      }
    };
    fetchData();
  }, [user, userId]);

  // ANCHOR - Create Delivery Challan
  const CreateDeliveryChallanContext = async (formData) => {
    const apiUrl = createDeliveryChallanRoute;
    try {
      setError(null); // Clear any previous errors
      const response = await axios.post(apiUrl, formData);
      setCreateDeliveryChallanState(response?.data);
      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message || "Order Filled!");
      } else {
        toast.error(error.response?.data?.message || "Order Failed");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "Order Failed", {
        position: "top-right",
        autoClose: 3000, // 3 seconds
      });
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  const deliveryChallanContext = async (userId) => {
    const userFilesUrl =
      DeliveryChallanContextRoutes.getDeliveryChallan(userId);
    try {
      const response = await axios.get(userFilesUrl);
      if (response.status === 200) {
        setFilesDeliveryChallan(response.data);
      }
    } catch (error) {
      console.error("Error fetching userId filesDeliveryChallan:", error);
    }
  };

  const getSalesOrder = async (userId) => {
    const apiUrl = GetSalesOrderRoutes.GetSalesOrder(userId);
    try {
      setError(null);
      const response = await axios.get(apiUrl);
      setGetDeliveryChallanForm(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      );
    }
  };

  const UpdateDeliveryChallan = async (formData, invoiceId) => {
    try {
      setError(null);
      const apiUrl = `${updateDeliveryChallanRoutes.DeliveryChallanRoutes(
        invoiceId
      )}`;
      const response = await axios.put(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          // Add any other required headers here
        },
        // Remove 'cors: "no-cors"' option
      });
      setUpdatedDeliveryChallanData(response?.data);

      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message);
        getSalesOrder();
      } else {
        toast.error(response?.data?.message); // Fixed error handling
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "An error occurred.", {
        position: "top-right",
        autoClose: 3000,
      });
      setError(error.response?.data?.message || "An error occurred.");
    }
  };

  const refreshFilesDeliveryChallan = useCallback(async () => {
    const apiUrl =
      RefreshFilesDeliveryChallanRoutes.RefreshFilesFormOrder(userId);
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const updatedData = await response.json();
      setUpdatedDeliveryChallanData(updatedData);
    } catch (error) {
      console.error("Error simulating data update:", error);
    }
  }, [userId]);

  const GenerateDeliveryChallan = async (fileId, invoiceNumber) => {
    setIsLoading(true);
    const apiUrl =
      GenerateDeliveryChallanRoutes?.GenerateDeliveryChallan(fileId);
    const invoiceName = invoiceNumber;
    // console.log(
    //   "🚀 ~ file: UserProviderMongo.jsx:287 ~ generateInvoice ~ invoiceName:",
    //   invoiceName
    // );

    try {
      const response = await axios.get(apiUrl, {
        responseType: "blob",
      });
      console.log("🚀 ~ GenerateDeliveryChallan ~ response:", response);

      // Log the entire response headers to check Content-Disposition
      console.log("Response Headers:", response.headers);

      const contentDisposition = response.headers["content-disposition"];
      const contentDispositionX = response.headers["X-Filename"];
      console.log(
        "🚀 ~ GenerateDeliveryChallan ~ contentDispositionX:",
        contentDispositionX
      );
      console.log(
        "🚀 ~ GenerateDeliveryChallan ~ contentDisposition:",
        contentDisposition
      );

      const filenameMatch =
        contentDisposition && contentDisposition.match(/filename=(.*?)(;|$)/);
      const suggestedFilename = filenameMatch
        ? decodeURIComponent(filenameMatch[1])
        : `Delivery_challan.pdf`;

      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = suggestedFilename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);

      setIsLoading(false);
    } catch (err) {
      console.log("🚀 ~ generateInvoice ~ err:", err);
      setError(err);
      setIsLoading(false);
    }
  };

  //ANCHOR - DELIVERY CHALLAN CRUD

  const getDeliveryChallanContext = async (userId) => {
    const apiUrl = DeliveryChallanContextRoutes.getDeliveryChallan(userId);
    try {
      setError(null);
      const response = await axios.get(apiUrl);
      SetGetDeliveryChallan(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      );
    }
  };

  // ANCHOR - Context function to fetch and download the XLSX template
  const deliveryChallanXlsxTemplateContext = async () => {
    const OrderDownloadRoute = GetExcelDeliveryChallanRoutes;

    try {
      // Fetch the XLSX template from the server
      const response = await axios({
        url: OrderDownloadRoute,
        method: "GET",
        responseType: "blob",
      });

      // Create a Blob object from the response data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element to trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = "DeliveryChallanXlsxTemplate.xlsx"; // Specify the file name here
      document.body.appendChild(a);

      // Trigger download
      a.click();

      // Clean up by revoking the URL and removing the anchor element
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      // Log and handle any errors that occur during the download process
      console.error("Error downloading XLSX file:", error);
      throw error; // Re-throw the error to be caught by the calling function (handleTemplateDownload)
    }
  };

  // ANCHOR - DELETE DELIVERY CHALLAN
  const handleSoftDelete = async (uid) => {
    // Make an API call to soft delete the order form
    try {
      // Convert the UID to a string if it's not already
      // const id = typeof uid === "string" ? uid : uid.toString();
      const id = uid;

      const apiUrl = `${DeleteDeliveryChallanRoutes.DeleteDeliveryChallan(id)}`;
      // const formData = { userId };
      const response = await axios.delete(apiUrl);

      if (response.ok) {
        // Update the local state to mark the order form as soft-deleted
        setDeleteOrderForms((prevOrderForms) =>
          prevOrderForms.map((form) =>
            form._id === id ? { ...form, deleted: true } : form
          )
        );
        await getSalesOrder();
      } else {
        console.error("Failed to soft delete the order form");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // ANCHOR - MIDLAND CUSTOMER INVOICES //

  // ANCHOR - Create Delivery Challan
  const CreateCustInvMidlandContext = async (formData) => {
    const apiUrl = createCustomerInvoicesRoute;
    try {
      setError(null); // Clear any previous errors
      const response = await axios.post(apiUrl, formData);
      setCreateCustomerInvoicesState(response?.data);
      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message || "Order Filled!");
      } else {
        toast.error(error.response?.data?.message || "Order Failed");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "Order Failed", {
        position: "top-right",
        autoClose: 3000, // 3 seconds
      });
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  // ANCHOR - CustInv XLSX template
  const CustInvXlsxTemplateContext = async () => {
    const OrderDownloadRoute = GetCustomerInvoicesRoutes;

    try {
      // Fetch the XLSX template from the server
      const response = await axios({
        url: OrderDownloadRoute,
        method: "GET",
        responseType: "blob",
      });

      // Create a Blob object from the response data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element to trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = "CustomerInvoiceXlsxTemplate.xlsx"; // Specify the file name here
      document.body.appendChild(a);

      // Trigger download
      a.click();

      // Clean up by revoking the URL and removing the anchor element
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      // Log and handle any errors that occur during the download process
      console.error("Error downloading XLSX file:", error);
      throw error; // Re-throw the error to be caught by the calling function (handleTemplateDownload)
    }
  };

  // ANCHOR -- GET Customer Invoices MIDLAND
  const getCustomerInvoicesContext = async (userId) => {
    const apiUrl = getCustomerInvoicesRoutes.getCustomerInvoices(userId);
    try {
      setError(null);
      const response = await axios.get(apiUrl);
      setCustomerInvoices(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      );
    }
  };

  // ANCHOR -- GET Customer Invoices MIDLAND
  const getGeneralTaxInvoiceContext = async (userId) => {
    const apiUrl = getGeneralTaxInvoiceRoutes;
    try {
      setError(null);
      const response = await axios.get(apiUrl);
      console.log("🚀 ~ getGeneralTaxInvoiceContext ~ response:", response);
      setGetGeneralTaxInvoice(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      );
    }
  };

  // ANCHOR -- UPDATE Customer Invoices MIDLAND
  const UpdateCustomerInvoicesMidlandChallanContext = async (
    formData,
    invoiceId
  ) => {
    try {
      setError(null);
      const apiUrl = `${updateDeliveryChallanRoutes.DeliveryChallanRoutes(
        invoiceId
      )}`;
      const response = await axios.put(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          // Add any other required headers here
        },
        // Remove 'cors: "no-cors"' option
      });
      setUpdatedDeliveryChallanData(response?.data);

      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message);
        getSalesOrder();
      } else {
        toast.error(response?.data?.message); // Fixed error handling
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "An error occurred.", {
        position: "top-right",
        autoClose: 3000,
      });
      setError(error.response?.data?.message || "An error occurred.");
    }
  };

  // ANCHOR - UPDATE Customer Invoices MIDLAND
  const GenerateCustomerInvoicesMidland = async (fileId, invoiceNumber) => {
    setIsLoading(true);
    const invoiceName = invoiceNumber;
    const invoiceId = fileId;
    console.log("🚀 ~ GenerateCustomerInvoicesMidland ~ invoiceId:", invoiceId);
    const apiUrl =
      getCustomerInvoicesPdfRoutes?.getCustomerInvoicesPdf(invoiceId);

    try {
      const response = await axios.get(apiUrl, {
        responseType: "blob",
      });
      console.log("🚀 ~ GenerateDeliveryChallan ~ response:", response);

      // Log the entire response headers to check Content-Disposition
      console.log("Response Headers:", response.headers);

      const contentDisposition = response.headers["content-disposition"];
      const contentDispositionX = response.headers["filename"];

      const filenameMatch =
        contentDisposition && contentDisposition.match(/filename=(.*?)(;|$)/);
      const suggestedFilename = filenameMatch
        ? decodeURIComponent(filenameMatch[1])
        : `midland_invoice.pdf`;

      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = suggestedFilename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);

      setIsLoading(false);
    } catch (err) {
      console.log("🚀 ~ generateInvoice ~ err:", err);
      setError(err);
      setIsLoading(false);
    }
  };

  // ANCHOR - GenerateTaxInvoiceGeneral
  const GenerateTaxInvoiceGeneral = async (fileId, invoiceNumber) => {
    setIsLoading(true);
    const invoiceName = invoiceNumber;
    console.log("🚀 ~ GenerateTaxInvoiceGeneral ~ invoiceName:", invoiceName);
    const invoiceId = fileId;
    console.log("🚀 ~ GenerateCustomerInvoicesMidland ~ invoiceId:", invoiceId);
    const apiUrl =
      getGeneralTaxInvoicePdfRoutes?.getGeneralTaxInvoicePdf(invoiceId);

    try {
      const response = await axios.get(apiUrl, {
        responseType: "blob",
      });
      console.log("🚀 ~ GenerateDeliveryChallan ~ response:", response);

      // Log the entire response headers to check Content-Disposition
      console.log("Response Headers:", response.headers);

      const contentDisposition = response.headers["content-disposition"];
      const contentDispositionX = response.headers["filename"];

      const filenameMatch =
        contentDisposition && contentDisposition.match(/filename=(.*?)(;|$)/);
      // const suggestedFilename = filenameMatch
      //   ? decodeURIComponent(filenameMatch[1])
      //   : `${invoiceName}.pdf`;

      const suggestedFilename = `${invoiceName}.pdf`;

      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = suggestedFilename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);

      setIsLoading(false);
    } catch (err) {
      console.log("🚀 ~ generateInvoice ~ err:", err);
      setError(err);
      setIsLoading(false);
    }
  };

  // GET VENDOR - CUSTOMER DATA
  // ANCHOR -- GET Customer Invoices MIDLAND
  const getVendorCustomerData = async (userId) => {
    const apiUrl = `${BaseUrl}/vendor-customer/getVendorCustomers`;
    try {
      setError(null);
      const response = await axios.get(apiUrl);
      console.log("🚀 ~ getVendorCustomerData ~ response:", response);
      console.log("🚀 ~ getVendorCustomerData ~ response:", response);
      setGetVendorCustomerDataTable(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      );
    }
  };

  const refereshGetVendorCustomerData = useCallback(async () => {
    const apiUrl = `${BaseUrl}/vendor-customer/getVendorCustomers`;

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const updatedData = await response.json();
      setGetVendorCustomerDataTable(updatedData);
    } catch (error) {
      console.error("Error simulating data update:", error);
    }
  }, [userId]);

  const deleteVendorCustomerContext = async (uid) => {
    // Make an API call to soft delete the order form
    try {
      // Convert the UID to a string if it's not already
      // const id = typeof uid === "string" ? uid : uid.toString();
      const id = uid;

      const apiUrl = `${BaseUrl}/vendor-customer/deleteVendorCustomer/${id}`;
      // const formData = { userId };
      const response = await axios.delete(apiUrl);

      if (response.ok) {
        // Update the local state to mark the order form as soft-deleted
        setDeleteVendorUser((prevOrderForms) =>
          prevOrderForms.map((form) =>
            form._id === id ? { ...form, deleted: true } : form
          )
        );
        toast.success("Vendor/Customer deleted successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        await getVendorCustomerData();
      } else {
        toast.success("Vendor/Customer deleted Failed!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error("Failed to soft delete the order form");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const value = {
    //CREATE DELIVERY CHALLAN VALUES
    CreateDeliveryChallanContext,
    deleteVendorCustomerContext,
    refereshGetVendorCustomerData,
    deleteVendorUser,
    getVendorCustomerData,
    getVendorCustomerDataTable,
    CreateDeliveryChallanState,
    //GET DELIVERY CHALLAN VALUES
    getDeliveryChallanForm,
    deliveryChallanContext,
    //GET DELIVERY CHALLAN VALUES
    filesDeliveryChallan,
    getSalesOrder,
    //Update DELIVERY CHALLAN VALUES
    UpdateDeliveryChallan,
    updatedDeliveryChallanData,
    refreshFilesDeliveryChallan,
    GenerateDeliveryChallan,
    // ANCHOR- delivery Challan
    getDeliveryChallanContext,
    getDeliveryChallan,
    // ANCHOR - DELIVERY CHALLAN XLSX FILE TEMPLATE
    deliveryChallanXlsxTemplateContext,
    getDeliveryChallanXlsxTemplate,
    // Anchor - delete
    deleteOrderForms,
    handleSoftDelete,
    //ANCHOR - CUSTOMER INVOICES MIDLAND
    CreateCustInvMidlandContext,
    CreateCustomerInvoicesState,
    // ANCHOR - CUSTOMER INVOICES MIDLAND XLSX
    CustInvXlsxTemplateContext,
    getCustomerInvoicesContext,
    getCustomerInvoices,
    getGeneralTaxInvoiceContext,
    getGeneralTaxInvoice,
    //ANCHOR - CUSTOMER INVOICE UPDATE
    UpdateCustomerInvoicesMidlandChallanContext,
    //
    GenerateCustomerInvoicesMidland,
    GenerateTaxInvoiceGeneral,
    isLoading,
    error,
  };

  return (
    <DeliveryChallanMongo.Provider value={value}>
      {children}
    </DeliveryChallanMongo.Provider>
  );
};

const useDeliveryChallanMongo = () => useContext(DeliveryChallanMongo);

export { DeliveryChallanMongoContext, useDeliveryChallanMongo };
