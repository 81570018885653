import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import "./productsAdmin.css";
import Spinner from "react-bootstrap/esm/Spinner.js";
import { Tooltip } from "react-tippy";
import AddIcon from "@mui/icons-material/AddCircleOutline"; // Plus icon
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useUser } from "../../../../contexts/adminPanel.context.jsx";
import PaginationComponent from "../../../pagination/PaginationComponent.jsx";
import LoadingOverlay from "../../../user-profile/order-history/LoadingOverlay.jsx";
import DeletedProductsAdmin from "../products/deletedProductsAdmin.jsx";
import MfiEdit from "./mfiCrud/mfiEdit.jsx";
import CreateProductAdmin from "../products/createProducts.jsx";
import DeleteConfirmationModal from "../../../success-modal/deleteModal.js";
import UploadProduct from "../products/uploadProduct.jsx";
import MfiCreateForm from "./mfiCrud/mfiCreate.jsx";
import { useAuth } from "../../../../contexts/UserProviderMongo.jsx";

function MfiIndex() {
  const { getAllMfi } = useAuth([]);
  const { createMfiContext, user } = useAuth();

  console.log(
    "🚀~File:ProductsAdmin.component.jsx:23~MfiIndex~GetAllMfi:",
    getAllMfi
  );
  const { getRefreshAllMfiContext } = useAuth();
  const { DeleteProductsWithAdminRole } = useUser();

  const pageSize = 10; // Number of rows per page

  const [getActiveProductsAdminPage, setgetActiveProductsAdminPage] =
    useState(null);
  const [currentPageForm, setCurrentPageForm] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [createProducts, setCreateProducts] = useState(null);
  const [uploadProduct, setUploadProduct] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUploadProductModal, setUploadProductModal] = useState(false);
  const [deleteFile, setDeleteFile] = useState(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  console.log(getActiveProductsAdminPage, "getActiveProductsAdminPage");

  //ANCHOR <!---- For Excel Order ----!>
  useEffect(() => {
    const getActiveProductsAdminMap = getAllMfi?.data;
    // console.log(
    //   "🚀 ~ file: order-history.jsx:57 ~ useEffect ~ files:",
    //   getActiveProductsAdminMap
    // );

    if (getActiveProductsAdminMap) {
      // If getSaleOrderAllFormMap is not null or undefined, set it in the state
      setgetActiveProductsAdminPage(getActiveProductsAdminMap);
    }
  }, [getAllMfi]);

  const handleEditClick = (file) => {
    setEditFile(file);
    setShowEditModal(true);
  };

  const handleCreateClick = () => {
    setCreateProducts();
    setShowCreateModal(true);
  };

  const handleUploadProductExcel = () => {
    setUploadProduct();
    setUploadProductModal(true);
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };
  const handleUploadProductModalClose = () => {
    setUploadProductModal(false);
  };

  const handleDeleteClick = (file) => {
    setDeleteFile(file);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async (file) => {
    if (file && file._id) {
      const uid = file._id;
      try {
        await DeleteProductsWithAdminRole(uid);
        setIsDeleteModalOpen(false);
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error("Invalid or missing _id in file object:", file);
    }
  };

  const handleCancelDelete = () => {
    // Cancel the delete operation and close the modal
    setIsDeleteModalOpen(false);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const handleRefresh = async () => {
    console.log("handle referesh clicked");
    setIsLoading(true);
    try {
      await getRefreshAllMfiContext();
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  if (isLoading) {
    return (
      <div>
        <h1>Refreshing Products</h1>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (
    !getAllMfi?.data ||
    !getAllMfi?.data?.length === 0 ||
    !getAllMfi == null
  ) {
    return (
      <div>
        <h1>User Files</h1>
        <Button onClick={handleRefresh}>Refresh Data</Button>{" "}
        <p>No files found for the user.</p>
      </div>
    );
  }

  // For Form Order
  const startIndexForm = (currentPageForm - 1) * pageSize;
  const endIndexForm = Math.min(
    startIndexForm + pageSize,
    getActiveProductsAdminPage?.length
  );
  const formUploadOrder = getActiveProductsAdminPage?.slice(
    startIndexForm,
    endIndexForm
  );
  const totalPagesForm = Math.ceil(
    getActiveProductsAdminPage?.length / pageSize
  );

  // console.log(
  //   "🚀 ~ file: productsAdmin.component.jsx:140 ~ MfiIndex ~ totalPagesForm:",
  //   formUploadOrder
  // );

  const handlePageChangeForm = (page) => {
    setCurrentPageForm(page);
  };

  // ANCHOR - FIELDS
  const fields = formUploadOrder?.map((file) => {
    return [
      { name: "Brand", value: file?.brand },
      { name: "Category", value: file?.category },
      { name: "Price", value: `${file?.price}` },
      // Add more fields as needed
    ];
  });

  return (
    <div className="order-history-body">
      <div className="form-upload-order-container">
        {" "}
        <h1>MFI List</h1>
        <Button className="order-history-Refresh" onClick={handleRefresh}>
          Refresh Data
        </Button>
        <button
          onClick={() => handleCreateClick()}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            marginLeft: "10px",
          }}
        >
          <AddIcon color="primary" />
        </button>
        {/* <button
          onClick={() => handleUploadProductExcel()}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            marginLeft: "10px",
          }}
        >
          <CloudUploadIcon color="primary" />
        </button> */}
        <Table striped bordered hover className="custom-table">
          <thead>
            <tr>
              <th>Person name</th>
              <th>Person email</th>
              <th>Person phone</th>
              <th>Pan Card</th>
              <th>GstIn</th>
              <th>registration Certificate</th>
              <th>Business Name</th>
              <th>Business Address</th>
              <th>Business City</th>
              <th>Business State</th>
              <th>Business PinCode</th>
              <th></th>
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody>
            {formUploadOrder?.map((file) => (
              <tr id="limited-row" key={file._id}>
                <td className="">{file?.contactPerson?.name ?? `NAN`}</td>
                <td className="">{file?.contactPerson?.email ?? `NAN`}</td>
                <td className="">{file?.contactPerson?.phone ?? `NAN`}</td>
                <td className="">{file?.documents?.panCard ?? `NAN`}</td>
                <td className="">{file?.documents?.gstin ?? `NAN`}</td>
                <td className="">
                  {file?.documents?.registrationCertificate ?? `NAN`}
                </td>
                <td className="">{file?.businessName ?? `NAN`}</td>
                <td className="">{file?.businessAddress ?? `NAN`}</td>
                <td className="">{file?.businessCity ?? `NAN`}</td>
                <td className="">{file?.businessState ?? `NAN`}</td>
                <td className="">{file?.businessPinCode ?? `NAN`}</td>
                <td className="">
                  <button
                    onClick={() => handleEditClick(file)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <EditIcon color="primary" />
                  </button>
                </td>
                <td className="">
                  <button
                    onClick={() => handleDeleteClick(file)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <DeleteIcon color="error" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <PaginationComponent
          currentPage={currentPageForm}
          totalPages={totalPagesForm}
          onPageChange={handlePageChangeForm}
        />
        {isLoading && <LoadingOverlay />}
      </div>
      {showEditModal && (
        <MfiEdit
          isOpen={showEditModal}
          onRequestClose={handleEditModalClose}
          file={editFile}
        />
      )}
      {showCreateModal && (
        <MfiCreateForm
          isOpen={showCreateModal}
          onRequestClose={handleCreateModalClose}
          file={createProducts}
        />
      )}
      {showUploadProductModal && (
        <UploadProduct
          isOpen={showUploadProductModal}
          onRequestClose={handleUploadProductModalClose}
          file={uploadProduct}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onRequestClose={handleCancelDelete}
          onConfirmDelete={handleConfirmDelete}
          file={deleteFile}
          fields={fields}
        />
      )}
    </div>
  );
}

export default MfiIndex;
