import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AddIcon from "@mui/icons-material/AddCircleOutline"; // Plus icon
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";
import PaginationComponent from "../../pagination/PaginationComponent.jsx";
import { useDeliveryChallanMongo } from "../../../contexts/deliveryChallanContext.jsx";
import DeleteConfirmationModal from "../../success-modal/deleteModal.js";
import EditCustomerInvoicesMidland from "../customer-invoices-midland/EditCustomerInvoicesMidland.jsx";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CreateGeneralTaxSingle() {
  const { getGeneralTaxInvoice } = useDeliveryChallanMongo([]);
  const { GenerateTaxInvoiceGeneral } = useDeliveryChallanMongo([]);

  const { refreshFilesDeliveryChallan } = useDeliveryChallanMongo([]);

  const { handleSoftDelete } = useDeliveryChallanMongo([]);

  const { orderDownload, refreshFilesFormOrder } = useAuth([]); // Get data and function from context

  const pageSize = 10; // Number of rows per page
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageForm, setCurrentPageForm] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [deleteFile, setDeleteFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [excelOrderForms, setExcelOrderForms] = useState([]); //for sales order form
  const [orderForms, setOrderForms] = useState([]); //for sales order form
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [createProducts, setCreateProducts] = useState(null);

  //ANCHOR  <!---- For Sale Order form ----!>
  useEffect(() => {
    const getSaleOrderAllFormMap = getGeneralTaxInvoice?.data;

    if (getSaleOrderAllFormMap) {
      // If getSaleOrderAllFormMap is not null or undefined, set it in the state
      setOrderForms(getSaleOrderAllFormMap);
    }
  }, [getGeneralTaxInvoice?.data]);

  const handleDeleteClick = async (file) => {
    setDeleteFile(file);
    setIsDeleteModalOpen(true);
    await handleSoftDelete();
  };

  const handleConfirmDelete = async (file) => {
    // e.preventDefault();
    const uid = file;
    try {
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelDelete = () => {
    // Cancel the delete operation and close the modal
    setIsDeleteModalOpen(false);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      await refreshFilesDeliveryChallan();
      // await refreshFilesFormOrder();
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   handleRefresh();
  // });

  if (isLoading) {
    return (
      <div>
        <h1>User Files</h1>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  // //ANCHOR  <!---- Form Order to check if files ?? [] ----!>
  // if (!orderForms || orderForms.length === 0) {
  //   return (
  //     <div>
  //       <h1>No Form Order Sales foundss</h1>
  //       <Button onClick={handleRefresh}>Refresh Data</Button>{" "}
  //     </div>
  //   );
  // }

  if (!orderForms || orderForms.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "top",
          height: "auto",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            padding: "2rem",
            borderRadius: "0.5rem",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h1 style={{ marginBottom: "1rem", textAlign: "center" }}>
            Start Generating Customer Invoice Now.
          </h1>
          <FontAwesomeIcon
            icon={faPen}
            size="2x"
            style={{ margin: "1rem", display: "block", textAlign: "right" }}
          />
          {/* Adjust size and style of the icon as needed */}
        </div>
      </div>
    );
  }

  //ANCHOR  <!---- Excel Order to check if files ?? [] ----!>
  //ANCHOR  <!---- For Excel Upload Order ----!>
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, excelOrderForms.length);
  // const excelUploadOrder = excelOrderForms.slice(startIndex, endIndex);
  const excelUploadOrder = Array.isArray(excelOrderForms)
    ? excelOrderForms.slice(startIndex, endIndex)
    : [];
  const totalPages = Math.ceil(excelOrderForms.length / pageSize);

  //ANCHOR  <!---- For Form Order ----!>
  const startIndexForm = (currentPageForm - 1) * pageSize;
  const endIndexForm = Math.min(startIndexForm + pageSize, orderForms?.length);
  const formUploadOrder = Array.isArray(orderForms)
    ? orderForms?.slice(startIndexForm, endIndexForm)
    : [];
  const totalPagesForm = Math.ceil(orderForms?.length / pageSize);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setCurrentPageForm(page);
  };
  const handlePageChangeForm = (page) => {
    setCurrentPageForm(page);
  };

  // Sort the formUploadOrder array by the invoiceDate in descending order
  const sortedFormUploadOrder = formUploadOrder.slice().sort((a, b) => {
    const dateA = new Date(a.invoiceDate);
    const dateB = new Date(b.invoiceDate);
    return dateB - dateA;
  });

  const handleDownload = async (file) => {
    const fileId = file._id;

    // Handle download logic here, using file._id or other data
    await orderDownload(fileId); // Example usage, replace with your actual download logic
  };

  const handleGenerateInvoice = async (file) => {
    const fileId = file?._id;
    const invoiceNumber = file?.mainInvoiceNumber || "invoice";

    // Handle generate invoice logic here, using file._id or other data
    await GenerateTaxInvoiceGeneral(fileId, invoiceNumber); // Example usage, replace with your actual generate invoice logic
  };

  const handleCreateClick = () => {
    setCreateProducts();
    setShowCreateModal(true);
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };
  const handleEditClick = (file) => {
    setEditFile(file);
    setShowEditModal(true);
  };

  return (
    <div className="order-history-body">
      {showCreateModal && (
        <TaxInvoiceModal
          isOpen={showCreateModal}
          onRequestClose={handleCreateModalClose}
          file={createProducts}
        />
      )}
      <div className="form-upload-order-container">
        <h1>General Tax Invoices</h1>
        <Button className="order-history-Refresh" onClick={handleRefresh}>
          Refresh Data
        </Button>
        {/* <button
          onClick={() => handleCreateClick()}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            marginLeft: "10px",
          }}
        >
          <AddIcon color="primary" />
        </button> */}
        <div className="table-responsive">
          <Table striped bordered hover className="custom-table">
            <thead>
              <tr>
                <th>Invoice Number</th>
                <th>billTo</th>
                <th>orderId</th>
                <th>Invoice Date</th>
                <th>Ship From</th>
                <th>Ship To</th>
                <th>Payment Terms</th>
                <th>Edit</th>
                <th>Delete</th>
                <th>Invoice</th>
              </tr>
            </thead>
            <tbody>
              {sortedFormUploadOrder.map((file) => (
                <tr key={file._id}>
                  <td>{file?.mainInvoiceNumber || "-"}</td>
                  <td>{file?.billTo?.name || "-"}</td>
                  <td>{file?._id || "-"}</td>
                  <td>{file?.invoiceDate || "-"}</td>
                  <td>{file?.shipFrom?.name || "-"}</td>
                  <td>{file?.shipTo[0]?.customerName || "-"}</td>
                  {/* <td>
                    {file?.shipTo?.map((item, index) => (
                      <div key={index}>
                        {item.bmName || "-"}: {item.address || "-"}
                      </div>
                    ))}
                  </td> */}
                  <td>{file.PaymentTerms || "-"}</td>
                  <td>
                    <div className="d-flex">
                      <button
                        disabled
                        onClick={() => handleEditClick(file)}
                        className="mr-2"
                      >
                        <EditIcon color="disabled" />
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                      <button onClick={() => handleDeleteClick(file._id)}>
                        <DeleteIcon />
                      </button>
                    </div>
                  </td>
                  <td>
                    <Button
                      className="order-history-Refresh"
                      onClick={() => handleGenerateInvoice(file)}
                    >
                      {isLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Generate Invoice"
                      )}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <PaginationComponent
          currentPage={currentPageForm}
          totalPages={totalPagesForm}
          onPageChange={handlePageChangeForm}
        />
        {isLoading && <LoadingOverlay />}
      </div>
      {showEditModal && (
        <EditCustomerInvoicesMidland
          isOpen={showEditModal}
          onRequestClose={handleEditModalClose}
          file={editFile}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onRequestClose={handleCancelDelete}
          onConfirmDelete={handleConfirmDelete}
          file={deleteFile}
        />
      )}
    </div>
  );
}

export default CreateGeneralTaxSingle;
