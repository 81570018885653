import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { BaseUrl } from "../api/user.api.js";

// Create ProductContext
export const ProductContext = createContext();

// Create Provider Component
export const ShaktiProductProviderContext = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch products from the API
  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/products/get/getShaktiActiveProducts`
      );
      console.log("🚀 ~ fetchProducts ~ response:", response);

      setProducts(response?.data); // Assuming the API response is an array of products
      setLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts(); // Only run once when the component mounts
  }, []); // Empty dependency array

  const value = {
    fetchProducts,
    products,
    loading,
  };

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
};

// Custom Hook for consuming the context
export const useProductContextMongo = () => useContext(ProductContext);
