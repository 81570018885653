import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { Receipt, History, Assignment, ListAlt } from "@mui/icons-material";
import ShaktiProductBulkUpdate from "./ShaktiProducts/ShaktiProductsUpdateBulk.js";
import ShaktiProductPage from "./ShaktiProducts/shaktiProducts.js";
import ShaktiProductBulkCreate from "./ShaktiProducts/ShaktiBulkProductCreate.js";

function ShaktiAdminMain() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    <ShaktiProductBulkCreate />,
    <ShaktiProductBulkUpdate />,
    <ShaktiProductPage />,
  ];

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f5f5f5",
      }}
    >
      {/* Sidebar */}
      <Box
        sx={{
          width: 280,
          backgroundColor: "#1e293b",
          color: "#fff",
          paddingTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRight: "1px solid #e0e0e0",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#38bdf8",
            marginBottom: 3,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Shakti Admin Panel
        </Typography>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Shakti Admin Tabs"
          TabIndicatorProps={{
            style: { backgroundColor: "#38bdf8", width: "4px" },
          }}
          sx={{ alignItems: "flex-start", width: "100%" }}
        >
          {tabItems.map((tabItem, index) => (
            <Tab
              key={index}
              label={
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  {tabItem.icon}
                  <span style={{ textAlign: "left" }}>{tabItem.label}</span>
                </Box>
              }
              sx={{
                color: value === index ? "#38bdf8" : "#e2e8f0",
                textAlign: "left",
                justifyContent: "flex-start",
                padding: 1.5,
                fontSize: "1rem",
                "&:hover": { color: "#38bdf8", backgroundColor: "#334155" },
              }}
            />
          ))}
        </Tabs>
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          flex: 1,
          padding: 3,
          backgroundColor: "#ffffff",
          borderRadius: 2,
          margin: 2,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {tabs[value]}
      </Box>
    </Box>
  );
}

export default ShaktiAdminMain;

const tabItems = [
  { label: "Bulk Product Create", icon: <Receipt /> },
  { label: "Bulk Product Update", icon: <History /> },
  { label: "Products Page", icon: <Assignment /> },
];
