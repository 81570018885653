import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Divider } from "@mui/material";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import SubmitButton from "../../bulk-order/submit.btn.component.jsx";
import ShipToButton from "../../bulk-order/shipToTemplateBtn.jsx";
import FileButtons from "../../bulk-order/fileButtons.jsx";
import { WarehouseContext } from "../../../contexts/warehouse.context.jsx";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";
import {
  shaktiBulkProductUpdateRoutes,
  shaktiBulkProductUpdateDownloadRoutes,
  shaktiBulkProductCreateRoutes,
  shaktiBulkProductCreateTemplateRoutes,
} from "../../../api/user.api.js";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px", // Increase padding for better spacing
    maxWidth: "800px", // Set a maximum width for the modal
    width: "100%", // Make the modal responsive
    maxHeight: "80vh", // Set a maximum height for the modal (80% of the viewport height)
    overflowY: "auto", // Enable vertical scrolling if the content exceeds the height
    margin: "10px",
  },
};

Modal.setAppElement("#root");
const ShaktiProductBulkCreate = ({ isOpen, onRequestClose }) => {
  const { getStaticDataS } = useAuth([]);
  console.log(
    "🚀 ~ file: bulk-upload.component.jsx:27 ~ ShaktiProductBulkCreate ~ getStaticDataS:",
    getStaticDataS
  );
  const { user } = useAuth();
  const { getSalesOrder } = useAuth([]);
  const { getAllWarehouseContent } = WarehouseContext([]);
  console.log(
    "🚀 ~ file: bulk-upload.component.jsx:29 ~ ShaktiProductBulkCreate ~ getAllWarehouseContent:",
    getAllWarehouseContent
  );

  const userId = user?.userId;
  console.log(
    "🚀 ~ file: bulk-upload.component.jsx:40 ~ ShaktiProductBulkCreate ~ userId:",
    userId
  );
  //For Upload of template
  const [uploadOrder, setUploadOrder] = useState(null);
  const [uploadOrderResData, setUploadOrderResData] = useState([]);
  const [uploadResCheck, setUploadResCheck] = useState(null);

  const data = uploadOrderResData?.data?.map((x) => x);

  const handleTemplateDownload = async (fileId) => {
    try {
      const response = await fetch(shaktiBulkProductCreateTemplateRoutes, {
        method: "GET",
      });

      if (response.status === 200) {
        const contentDisposition = response.headers.get("Content-Disposition");
        const filenameMatch =
          contentDisposition && contentDisposition.match(/filename="(.+)"/);
        const filename = filenameMatch
          ? filenameMatch[1]
          : "Product_Bulk_Update_Template";

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        if (response.status === 200 || response.status === 201) {
          toast.success("Template Downloaded Successfully");
        } else {
          toast.error("Template Download Failed");
        }
      } else {
        console.error("Template Download Failed");
      }
    } catch (error) {
      console.error("Error during Template download:", error);
    }
  };

  const handleShipToTemplateDownload = async (fileId) => {
    try {
      const response = await fetch(GetShipToExcelDummyRoutes, {
        method: "GET",
      });

      if (response.status === 200) {
        const contentDisposition = response.headers.get("Content-Disposition");
        console.log(
          "🚀 ~ handleShipToTemplateDownload ~ contentDisposition:",
          contentDisposition
        );
        const filenameMatch =
          contentDisposition && contentDisposition.match(/filename="(.+)"/);
        console.log(
          "🚀 ~ handleShipToTemplateDownload ~ filenameMatch:",
          filenameMatch
        );
        const filename = filenameMatch
          ? filenameMatch[1]
          : "ShipToTemplateXlsx";

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        if (response.status === 200 || response.status === 201) {
          toast.success("Template Downloaded Successfully");
        } else {
          toast.error("Template Download Failed");
        }
      } else {
        console.error("Template Download Failed");
      }
    } catch (error) {
      console.error("Error during Template download:", error);
    }
  };

  // Form state
  const [formData, setFormData] = useState({
    file: null,
    // shipToFile: null,
    // shipFrom: { name: "", shipFromIdReq: "" },
    // invoiceDate: "",
    // billTo: { name: "", address: "", taxId: "" },
    // shipTo: [{ name: "", address: "", placeOfSupplyShip: "" }],
    // userId: 4,
    // PaymentTerms: "",
  });
  const [selectedProducts, setSelectedProducts] = useState(
    formData?.shipTo?.map(() => "")
  );
  const [productMapping, setProductMapping] = useState({});

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      // Split the name into an array to access nested properties
      const nameArray = name.split(".");

      // Handle nested properties
      if (nameArray.length === 2) {
        return {
          ...prevData,
          [nameArray[0]]: {
            ...prevData[nameArray[0]],
            [nameArray[1]]: value,
          },
        };
      }

      // Handle non-nested properties
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setFormData((prevData) => ({
      ...prevData,
      file: file,
    }));
  };

  // Handle file input change
  const handleShipToFileChange = (e) => {
    const file = e.target.files[0];

    setFormData((prevData) => ({
      ...prevData,
      shipToFile: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.file) {
      toast.error("Please select a file before submitting.");
      return;
    }

    // Create a FormData object
    const data = new FormData();
    data.append("file", formData.file); // Append only the file to the FormData object

    try {
      // Make Axios call
      const response = await axios.post(shaktiBulkProductCreateRoutes, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200 || response.status === 201) {
        console.log(response.data);
        toast.success("File uploaded successfully!");

        // Clear file input after successful submission
        setFormData((prevData) => ({
          ...prevData,
          file: null,
        }));

        // Optionally clear the file input field in the DOM
        document.querySelector('input[name="file"]').value = "";
      } else {
        toast.error("Failed to upload the file.");
        // Clear file input after successful submission
        setFormData((prevData) => ({
          ...prevData,
          file: null,
        }));
      }
    } catch (error) {
      console.error("Error while uploading file:", error);
      toast.error("An error occurred. Please try again later.");
      // Clear file input after successful submission
      setFormData((prevData) => ({
        ...prevData,
        file: null,
      }));
    }
  };

  const handleNameChange = (e, index) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const newProducts = [...prevData.shipTo];
      newProducts[index] = { ...newProducts[index], name: value };

      const newSelectedProducts = newProducts?.map(
        (product) => product.name || ""
      );

      setSelectedProducts(newSelectedProducts);

      return {
        ...prevData,
        shipTo: newProducts,
      };
    });
  };

  const handleAddressChange = (e, index) => {
    const { value } = e.target;

    setFormData((prevData) => {
      const newProducts = [...prevData.shipTo];
      newProducts[index]["address"] = value;

      const newSelectedProducts = newProducts?.map(
        (product) => product.name || ""
      );

      setSelectedProducts(newSelectedProducts);

      return {
        ...prevData,
        shipTo: newProducts,
      };
    });
  };

  const handlePlaceOfSupplyShipChange = (e, index) => {
    const { value } = e.target;

    setFormData((prevData) => {
      const newProducts = [...prevData.shipTo];
      newProducts[index]["placeOfSupplyShip"] = value;

      const newSelectedProducts = newProducts?.map(
        (product) => product.name || ""
      );

      setSelectedProducts(newSelectedProducts);

      return {
        ...prevData,
        shipTo: newProducts,
      };
    });
  };

  const handleAddProductFields = (index) => {
    const isLastProductEmpty =
      formData.shipTo[index].name === "" &&
      formData.shipTo[index].address === "" &&
      formData.shipTo[index].placeOfSupplyShip === "";

    if (!isLastProductEmpty) {
      setFormData((prevData) => {
        const newProducts = [
          ...prevData.shipTo,
          { name: "", address: "", placeOfSupplyShip: "" },
        ];
        return {
          ...prevData,
          shipTo: newProducts,
        };
      });
    }
  };

  const handleDeleteProductFields = (index) => {
    setFormData((prevData) => {
      if (prevData.shipTo.length === 1) {
        // Ensure there is always at least one product field
        return prevData;
      }

      const newProducts = [...prevData.shipTo];
      newProducts.splice(index, 1);
      return {
        ...prevData,
        shipTo: newProducts,
      };
    });
  };

  return (
    <div>
      <div className="file-buttons">
        <div className="form-container">
          <form onSubmit={handleSubmit} className="invoice-form">
            {/* billTo */}
            <div className="tax-invoice-container-bulk-upload">
              {/* <h2 className="text-left font-bold">Tax Invoice</h2> */}
              <h2 className="text-center">Create Bulk Create For Products</h2>

              {/* shipTo */}
              <div className="row mt-10">
                <div className="col-sm-6">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="file">
                      {" "}
                      Product File:
                    </label>
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileChange}
                      required
                      style={{
                        lineHeight: "24px",
                        borderRadius: "10px",
                        background: "#F1F3F4",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "left",
                  width: "100%",
                }}
              >
                <FileButtons onDownloadClick={handleTemplateDownload} />
                {/* <ShipToButton onDownloadClick={handleShipToTemplateDownload} /> */}
                <div style={{ marginLeft: "20px" }}>
                  {" "}
                  {/* Adjust the margin as needed */}
                  <SubmitButton handleSubmit={handleSubmit} />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="mt-10"></div>
        <Divider />
        {/* <OrderHistory className="mt-10" /> */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default ShaktiProductBulkCreate;
