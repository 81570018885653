import React from "react";

const VisionShaktiMain = () => {
  return (
    <div style={styles.pageContainer}>
      <header style={styles.heroSection}>
        <img
          src="/JriseAlliancPvtLtdLogo.png"
          alt="Jrise Logo"
          style={styles.logo}
        />
        <h1 style={styles.heroTitle}>Empowering Entrepreneurs with Shakti</h1>
        <p style={styles.heroSubtitle}>
          Join a growing network of changemakers and drive economic growth in
          your community.
        </p>
        <a
          href="https://play.google.com/store/apps/details?id=com.jrise.shakti&pcampaignid=web_share"
          style={styles.downloadLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download the App
        </a>
      </header>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>About Shakti</h2>
        <p style={styles.paragraph}>
          Shakti operates in multiple states, empowering entrepreneurs to build
          and grow their businesses. Our model is designed to streamline
          processes for Business Correspondents (BCs), Managers, and State
          Heads, fostering economic growth and development.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>States We Are Present In</h2>
        <p style={styles.paragraph}>
          Shakti is currently operational in several key states, enabling access
          to products, services, and business support for local entrepreneurs.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Category-wise Products and Brands</h2>
        <p style={styles.paragraph}>
          Shakti offers a diverse range of products categorized by type, making
          it easier for entrepreneurs to browse, select, and promote items for
          their customers.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Download the Shakti App</h2>
        <p style={styles.paragraph}>
          Experience the power of Shakti at your fingertips. Download the Shakti
          app to explore products, manage orders, and grow your business with
          ease.
        </p>
        <a
          href="https://play.google.com/store/apps/details?id=com.jrise.shakti&pcampaignid=web_share"
          style={styles.downloadLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download the App
        </a>
      </section>

      <footer style={styles.footer}>
        <p>Jrise Alliances Pvt Ltd © 2024. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

const styles = {
  pageContainer: {
    fontFamily: "Arial, sans-serif",
    color: "#333",
    backgroundColor: "#f4f6f8",
    padding: "0",
    margin: "0",
  },
  heroSection: {
    backgroundColor: "#007bff",
    color: "#fff",
    textAlign: "center",
    padding: "60px 20px",
    borderBottomLeftRadius: "50px",
    borderBottomRightRadius: "50px",
  },
  logo: {
    width: "120px",
    marginBottom: "20px",
  },
  heroTitle: {
    fontSize: "48px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  heroSubtitle: {
    fontSize: "20px",
    marginBottom: "30px",
    color: "white",
  },
  heroButton: {
    padding: "15px 40px",
    fontSize: "18px",
    backgroundColor: "white",
    color: "#000",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    textDecoration: "none",
  },
  section: {
    margin: "40px 20px",
    background: "white",
    padding: "40px",
    borderRadius: "12px",
    height: "min-content",
    boxShadow: "0 6px 10px rgba(0,0,0,0.1)",
  },
  sectionTitle: {
    fontSize: "32px",
    color: "#007bff",
    borderBottom: "3px solid #007bff",
    paddingBottom: "10px",
    marginBottom: "20px",
  },
  paragraph: {
    fontSize: "18px",
    lineHeight: "1.8",
  },
  downloadLink: {
    display: "inline-block",
    padding: "15px 30px",
    fontSize: "18px",
    backgroundColor: "#28a745",
    color: "#fff",
    borderRadius: "8px",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer",
  },
  footer: {
    textAlign: "center",
    fontSize: "16px",
    color: "#555",
    marginTop: "40px",
  },
};

export default VisionShaktiMain;
