import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App.jsx";
import ReactFlows from "reactflow";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserProvider } from "./contexts/adminPanel.context.jsx";
import { UserProviderMongoContext } from "./contexts/UserProviderMongo.jsx";
import { CategoriesProvider } from "./contexts/categories.context.jsx";
import { CartProvider } from "./contexts/cart.context.jsx";

import "./index.scss";
import { ProductsProvider } from "./contexts/product.context.jsx";
import { HomePageContext } from "./contexts/homePage.context.jsx";
import { WarehouseContexts } from "./contexts/warehouse.context.jsx";
import { TaxInvoiceMongoContext } from "./contexts/taxInvoiceContext.jsx";
import { DeliveryChallanMongoContext } from "./contexts/deliveryChallanContext.jsx";
import { ShaktiProductProviderContext } from "./contexts/ProductContext.jsx";

const rootElement = document.getElementById("root");

render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProviderMongoContext>
        <UserProvider>
          <ShaktiProductProviderContext>
            <TaxInvoiceMongoContext>
              <DeliveryChallanMongoContext>
                <WarehouseContexts>
                  <HomePageContext>
                    <CategoriesProvider>
                      <ProductsProvider>
                        <CartProvider>
                          <App />
                          <ToastContainer />
                        </CartProvider>
                      </ProductsProvider>
                    </CategoriesProvider>
                  </HomePageContext>
                </WarehouseContexts>
              </DeliveryChallanMongoContext>
            </TaxInvoiceMongoContext>
          </ShaktiProductProviderContext>
        </UserProvider>
      </UserProviderMongoContext>
    </BrowserRouter>
  </React.StrictMode>,
  rootElement
);
