import React, { useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  AppBar,
  Toolbar,
  Drawer,
  Fade,
} from "@mui/material";
import {
  Receipt,
  History,
  Assignment,
  ListAlt,
  Person,
} from "@mui/icons-material";
import GeneralTaxInvoice from "./general-tax-invoice.jsx";
import OrderHistory from "../user-profile/order-history/order-history.jsx";
import InvoiceHistory from "./invoiceHistory.jsx";
import EditInvoicePage from "./editTaxInvoice.jsx";
import GetDeliveryChallan from "./delhivery-challan/getDeliveryChallan.jsx";
import CreateDeliveryChallan from "./delhivery-challan/createDeliveryChallan.jsx";
import CreateCustomerInvoicesMidland from "./customer-invoices-midland/CreateCustomerInvoicesMidland.jsx";
import GetCustomerInvoiceMidland from "./customer-invoices-midland/GetCustomerInvoiceMidland.jsx";
import CreateGeneralTaxSingle from "./single-general-tax-invoice/singleGeneralTaxInvoice.jsx";
import UpdateCreditNoteStatus from "./customer-invoices-midland/UpdateCreditNoteStatus.jsx";
import VendorCustomerTable from "./vendor_customer/vendorCustomerTable.js";

function InvoiceGenerate() {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const tabs = [
    <VendorCustomerTable />,
    <GeneralTaxInvoice />,
    <CreateGeneralTaxSingle />,
    <CreateDeliveryChallan />,
    <GetDeliveryChallan />,
    <CreateCustomerInvoicesMidland />,
    <GetCustomerInvoiceMidland />,
    <UpdateCreditNoteStatus />,
  ];

  const SidebarContent = (
    <Box
      style={{
        width: 280,
        background: "linear-gradient(180deg, #1e293b 0%, #334155 100%)",
        minHeight: "100vh",
        color: "#fff",
        paddingTop: 24,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRight: "1px solid #e0e0e0",
        position: "sticky",
        top: 0,
      }}
    >
      <Typography
        variant="h6"
        style={{
          color: "#38bdf8",
          marginBottom: 16,
          textAlign: "center !important",
          fontWeight: "bold",
        }}
      >
        Invoice Admin
      </Typography>

      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Admin Panel Tabs"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#38bdf8",
            width: 4,
          },
        }}
        style={{ alignItems: "flex-start" }}
      >
        {tabItems.map((tabItem, index) => (
          <Tab
            key={index}
            label={
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "left",
                  gap: 8,
                  paddingLeft: 0,
                  width: "100%",
                  justifyContent: "flex-start",
                }}
              >
                {tabItem.icon}
                <span>{tabItem.label}</span>
              </Box>
            }
            style={{
              color: value === index ? "#e2e8f0" : "#e2e8f0",
              backgroundColor: value === index ? "#0D6EFD" : "transparent",
              textAlign: "left",
              width: "100%",
              paddingLeft: 16,
              borderRadius: 8,
              margin: "5px 10px",
              justifyContent: "flex-start",
              padding: 16,
              fontSize: "1rem",
              transition: "all 0.3s ease-in-out",
            }}
          />
        ))}
      </Tabs>
    </Box>
  );

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Drawer
        variant="temporary"
        open={open}
        onClose={handleDrawerToggle}
        style={{
          width: 280,
          display: "block",
        }}
      >
        {SidebarContent}
      </Drawer>

      <Box
        style={{
          display: "block",
          width: 280,
        }}
      >
        {SidebarContent}
      </Box>

      <Box
        style={{
          flex: 1,
          padding: 0,
          borderRadius: 16,
          margin: 16,
        }}
      >
        <Fade in={!!tabs[value]} timeout={500}>
          {tabs[value] ? (
            <Box
              style={{
                flex: 1,
                padding: 0,
                backgroundColor: "#f9fafb",
                height: "100vh",
                borderRadius: 16,
                margin: 0,
                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.08)",
              }}
            >
              {tabs[value]}
            </Box>
          ) : null}
        </Fade>
      </Box>
    </Box>
  );
}

export default InvoiceGenerate;

const tabItems = [
  { label: "Vendor/Customer Registration", icon: <Receipt /> },
  { label: "Create Invoice General (Bulk)", icon: <Receipt /> },
  { label: "Invoice History General", icon: <History /> },
  { label: "Create Delivery Challan", icon: <Assignment /> },
  { label: "Delivery Challan History", icon: <ListAlt /> },
  { label: "Create Customer Invoices (Midland)", icon: <Person /> },
  { label: "Customer Invoices (Midland) History", icon: <ListAlt /> },
  { label: "Cancel Invoice Midland", icon: <ListAlt /> },
];
