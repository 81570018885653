import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  CreateBannerHomePageRoutes,
  CreateBrandsHomePageRoutes,
  CreateWarehouseDataRoutes,
  CreateWarehouseInwardDataRoutes,
  DeleteBannerHomePageRoutes,
  GetAllWarehouseDataRoutes,
  GetBannerHomePageRoutes,
  GetBrandsHomePageRoutes,
  GetWarehouseDataRoutes,
  GetWarehouseInwardDataRoutes,
  UpdateBannerHomePageRoutes,
  UpdateBrandsHomePageRoutes,
  UpdateWarehouseDataRoutes,
  UpdateWarehouseInwardDataRoutes,
} from "../api/user.api.js";
import { useAuth } from "./UserProviderMongo.jsx";

// Create a context
const Warehouse = createContext();

// Create a provider component
export const WarehouseContexts = ({ children }) => {
  // Define your state variables and functions here
  const [data, setData] = useState(null);
  const [getWarehouseContent, setGetWarehouseContent] = useState(null);
  const [getBrandsContent, SetGetBrandsContent] = useState(null);
  const [createBrandsContent, SetCreateBrandsContent] = useState(null);
  const [deleteBannerContent, SetDeleteBannerContent] = useState(null);
  const [updateWareHouseContent, setUpdateWareHouseContent] = useState(null);
  const [updateBrandsContent, SetBrandsBannerContent] = useState(null);
  const [CreateWarehouseContent, SetCreateWarehouseContent] = useState(null);

  // ANCHOR - <!-- get Warehouse Inward UseSDtate --- >
  const [getWarehouseInwardContent, setGetWarehouseInwardContent] =
    useState(null);
  const [getAllWarehouseContent, setGetAllWarehouseContent] = useState(null);
  const [refreshWarehouseInwardContent, setRefreshWarehouseInwardContent] =
    useState(null);
  const [CreateWarehouseInward, SetCreateWarehouseInward] = useState(null);
  const [UpdateWarehouseInward, SetUpdateWarehouseInward] = useState(null);

  const [error, setError] = useState(null);
  const { user } = useAuth();

  const userId = user?.userId; // user id

  // Example function to update the context data
  const updateData = (newData) => {
    setData(newData);
  };

  // ANCHOR - GET warehouse Content
  const getWarehouseContext = async () => {
    const apiUrl = GetAllWarehouseDataRoutes;
    console.log(
      "🚀 ~ file: warehouse.context.jsx:44 ~ getWarehouseContext ~ apiUrl:",
      apiUrl
    );
    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl);
      console.log(
        "🚀 ~ file: warehouse.context.jsx:51 ~ getWarehouseContext ~ response:",
        response
      );
      setGetWarehouseContent(response?.data?.data);
      console.log(
        "🚀 ~ file: warehouse.context.jsx:48 ~ getWarehouseContext ~ response?.data:",
        response?.data?.data
      );
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - GET warehouse All Content
  const getAllWarehouseContext = async () => {
    const apiUrl = GetAllWarehouseDataRoutes;
    console.log(
      "🚀 ~ file: warehouse.context.jsx:44 ~ getWarehouseContext ~ apiUrl:",
      apiUrl
    );
    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl);
      console.log(
        "🚀 ~ file: warehouse.context.jsx:51 ~ getWarehouseContext ~ response:",
        response
      );
      setGetAllWarehouseContent(response?.data);
      console.log(
        "🚀 ~ file: warehouse.context.jsx:48 ~ getWarehouseContext ~ response?.data:",
        response?.data
      );
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - Create warehouse Content
  const CreateWarehouseContext = async (formDataCreate) => {
    try {
      setError(null); // Clear any previous errors
      const response = await axios.post(
        CreateWarehouseDataRoutes,
        formDataCreate
      );
      SetCreateWarehouseContent(response?.data);
      await getWarehouseContext();
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - update Banner Content
  const UpdateWarehouseContext = async (uniqueId, formDataHandleSave) => {
    const warehouseId = uniqueId;
    const apiUrl = `${UpdateWarehouseDataRoutes.UpdateWarehouseData(
      warehouseId
    )}`;

    try {
      setError(null); // Clear any previous errors
      const response = await axios.put(apiUrl, formDataHandleSave);
      setUpdateWareHouseContent(response?.data);
      await getWarehouseContext();
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - Delete Banner Content
  const DeleteBannerContext = async (uniqueId) => {
    const id = uniqueId;
    const apiUrl = `${DeleteBannerHomePageRoutes.DeleteBannerHomePage(id)}`;

    try {
      setError(null); // Clear any previous errors
      const response = await axios.delete(apiUrl);
      SetDeleteBannerContent(response?.data);
      await getWarehouseContext();
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - GET Brands Content
  const getBrandsContext = async () => {
    const apiUrl = GetBrandsHomePageRoutes;
    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl);
      SetGetBrandsContent(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - Create Banner Content
  const createBrandsContext = async (formDataCreate) => {
    try {
      setError(null); // Clear any previous errors
      const response = await axios.post(
        CreateBrandsHomePageRoutes,
        formDataCreate
      );
      SetCreateBrandsContent(response?.data);
      await getBrandsContext();
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - update Brands Content
  const UpdateBrandsContext = async (uniqueId, formDataHandleSave) => {
    const id = uniqueId;
    const apiUrl = `${UpdateBrandsHomePageRoutes.UpdateBrandsHomePage(id)}`;

    try {
      setError(null); // Clear any previous errors
      const response = await axios.put(apiUrl, formDataHandleSave);
      SetBrandsBannerContent(response?.data);
      await getBrandsContext();
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR <!----- warehouse inward ------>
  // warehouse Creation
  const createWarehouseInwardContext = async (formDataCreate) => {
    const apiUrl = CreateWarehouseInwardDataRoutes;
    try {
      setError(null); // Clear any previous errors
      const response = await axios.post(apiUrl, formDataCreate);
      SetCreateWarehouseInward(response.data);
      await getWarehouseInwardContext();
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  // ANCHOR - GET warehouse inward Content
  const getWarehouseInwardContext = async () => {
    const apiUrl = GetWarehouseInwardDataRoutes.GetWarehouseInwardData(userId);
    console.log(
      "🚀 ~ file: warehouse.context.jsx:44 ~ getWarehouseContext ~ apiUrl:",
      apiUrl
    );
    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl);
      console.log(
        "🚀 ~ file: warehouse.context.jsx:51 ~ getWarehouseContext ~ response:",
        response
      );
      setGetWarehouseInwardContent(response?.data);
      console.log(
        "🚀 ~ file: warehouse.context.jsx:48 ~ getWarehouseContext ~ response?.data:",
        response?.data
      );
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - GET warehouse inward Content
  const getWarehouseInwardRefreshContext = async () => {
    const apiUrl = GetWarehouseInwardDataRoutes.GetWarehouseInwardData(userId);
    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl); // Use axios.get
      const updatedData = response.data; // Get response data directly

      setRefreshWarehouseInwardContent(updatedData);
      setGetWarehouseInwardContent(updatedData); // Update the context state

      console.log("Updated data:", updatedData);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      );
    }
  };

  // Billing Data put
  const UpdateWarehouseInwardContext = async (warehouseId, updatedData) => {
    const ApiUrl =
      UpdateWarehouseInwardDataRoutes.UpdateWarehouseInwardData(warehouseId);
    try {
      setError(null); // Clear any previous errors
      const response = await axios.put(ApiUrl, updatedData);
      // Handle a successful response here
      SetUpdateWarehouseInward(response.data);
      await getWarehouseInwardContext();
    } catch (error) {
      console.error(error);

      setError(
        error.response?.data?.message || "An error occurred during the update."
      ); // Handle errors here
    }
  };

  useEffect(() => {
    getWarehouseContext();
    getWarehouseInwardContext();
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      await getAllWarehouseContext();
    };

    fetchData(); // Call the async function
  }, []); // Empty dependency array to run the effect only once

  const state = {
    data,
    error,
    updateData,
    updateWareHouseContent,
    getWarehouseContent,
    UpdateWarehouseContext,
    deleteBannerContent,
    DeleteBannerContext,
    CreateWarehouseContext,
    CreateWarehouseContent,
    getBrandsContent,
    getBrandsContext,
    createBrandsContext,
    createBrandsContent,
    UpdateBrandsContext,
    updateBrandsContent,
    getWarehouseInwardContent,
    getWarehouseInwardContext,
    getWarehouseInwardRefreshContext,
    refreshWarehouseInwardContent,
    CreateWarehouseInward,
    createWarehouseInwardContext,
    UpdateWarehouseInward,
    UpdateWarehouseInwardContext,
    getAllWarehouseContent,
    getAllWarehouseContext,
  };

  return <Warehouse.Provider value={state}>{children}</Warehouse.Provider>;
};

// Create a custom hook for accessing the context
export const WarehouseContext = () => {
  return useContext(Warehouse);
};
