import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Home from "./routes/home/home.component.jsx";
import Navigation from "./routes/navigation/navigation.component.jsx";
import Authentication from "./routes/authentication/authentication.component.jsx";
import Shop from "./routes/shop/shop.component.jsx";
import Registration from "./routes/registration/registration.component.jsx";
import BulkUpload from "./routes/bulkOrder/bulkOrder.component.jsx";
import Checkout from "./routes/checkout/checkout.component.jsx";
import SignUp from "./components/sign-up-page/signUp.jsx";
import SignIn from "./components/sign-up-page/signIn/signIn.jsx";
import OrderHistory from "./components/user-profile/order-history/order-history.jsx";
import Invoices from "./components/user-profile/invoices/invoices.jsx";
import ProfileSetting from "./components/user-profile/profile/profile.jsx";
import AdminIndex from "./components/admin-page/adminIndex.jsx";
import ProductDetail from "./components/shop-page/ProductDetail.jsx";
import WarehouseIndex from "./components/warehouse-page/warehouseIndex.jsx";
import { useAuth } from "./contexts/UserProviderMongo.jsx";
import { useEffect, useState } from "react";
import axios from "axios";
import { ValidateTokenRoutes } from "./api/user.api.js";
import ReactFlows from "reactflow";
import InvoiceGenerate from "./components/invoice-generation/invoiceGenerate.jsx";
import PrivacyPolicy from "./components/privacy-policys/privacyPolicy.jsx";
import RefundPolicy from "./components/privacy-policys/refund_policy.jsx";
import TermsAndCondition from "./components/privacy-policys/terms_&_conditions.jsx";
import VisionShaktiMain from "./components/VisionShakti/VisionShaktiMain.js";
import ShaktiProducts from "./components/ShaktiProducts/ShaktiProducts.jsx";
import Auth from "./components/sign-up-page/auth.js";
import ShaktiAdminMain from "./components/Shakti-Admin-Panel/ShaktiAdminMain.js";

const ProtectedRoute = ({ element: Element, isAuthenticated }) => {
  return isAuthenticated ? <Element /> : <Navigate to="/" replace />;
};

const getCookie = (cookieName) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null; // Return null if the cookie is not found
};

const App = () => {
  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isAuth, setAuth] = useState(null); // Set initial value based on the token

  // Replace this with your actual authentication logic
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    setIsAuthenticated(userId);
    setAuth(userId);
  }, []);

  useEffect(() => {
    // Check the authentication status on page refresh
    const storedToken = localStorage.getItem("userToken");
    if (storedToken) {
      // Assuming your backend has an endpoint to validate the token
      // You might need to replace 'validateTokenEndpoint' with the actual endpoint
      axios
        .post(ValidateTokenRoutes, { token: storedToken })
        .then((response) => {
          // console.log("🚀 ~ file: App.js:66 ~ .then ~ response:", response);
          if (response.data.success) {
            // If the token is valid, update the authentication status
            setAuth(true);
            setUserId(response.data.decoded.userId);
          } else {
            // If the token is invalid or expired, clear the token from localStorage
            localStorage.removeItem("userToken");
            setAuth(false);
          }
        })
        .catch((error) => {
          console.error("Error validating token:", error);
          setAuth(false);
        });
    } else {
      setAuth(false);
    }
  }, []);

  // useEffect(() => {
  //   if (!isAuthenticated && !window.location.pathname.startsWith("/")) {
  //     // If the user is not authenticated and is not trying to access a /signIn route,
  //     // navigate to the '/signIn' route
  //     navigate("/");
  //   }
  // }, [isAuthenticated, navigate]);

  const handleLogout = () => {
    // Implement your logout logic, e.g., clearing local storage, API calls, etc.
    logout();
    navigate("/signIn");
  };

  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path="shop/*" element={<Shop />} />
        <Route path="/ShaktiProducts" element={<ShaktiProducts />} />
        <Route path="VisionShakti/*" element={<VisionShaktiMain />} />
        <Route path="PrivacyPolicy/*" element={<PrivacyPolicy />} />
        <Route exact path="/refund-policy" element={<RefundPolicy />} />
        <Route path="TermsAndCondition/*" element={<TermsAndCondition />} />
        <Route path="/product/:productId" element={<ProductDetail />} />
        <Route path="/registration" element={<Invoices />} />
        <Route path="/order" element={<BulkUpload />} />
        <Route path="/invoice-generate" element={<InvoiceGenerate />} />
        <Route path="/ShaktiAdmin" element={<ShaktiAdminMain />} />
        <Route path="auth" element={<Authentication />} />

        {/* Sign-in and Sign-up routes */}
        <Route path="signIn" element={<SignIn />} />
        <Route path="/SignUp" element={<Auth />} />

        {/* Protected routes with conditional rendering */}
        <Route
          path="checkout"
          element={
            <ProtectedRoute
              element={<Checkout />}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="orderHistory"
          element={
            isAuth ? <OrderHistory /> : <Navigate to="/SignUp" replace />
          }
        />
        <Route
          path="profile"
          element={
            isAuth ? <ProfileSetting /> : <Navigate to="/SignUp" replace />
          }
        />
        <Route
          path="invoices"
          element={isAuth ? <Invoices /> : <Navigate to="/SignUp" replace />}
        />
        <Route
          path="adminPage"
          element={isAuth ? <AdminIndex /> : <Navigate to="/SignUp" replace />}
        />
        <Route
          path="warehouse"
          element={
            isAuth ? <WarehouseIndex /> : <Navigate to="/SignUp" replace />
          }
        />
        {/* Logout route */}
        <Route
          path="logout"
          element={<Navigate to="/SignUp" replace />}
          beforeEnter={() => handleLogout()}
        />
      </Route>
    </Routes>
  );
};

export default App;
