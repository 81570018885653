// let BaseUrl =
//   process.env.NODE_ENV != "production"
//     ? "http://52.172.40.17:3002"
//     : "http://localhost:3002";

// let BaseUrl =
// //   process.env.NODE_ENV == "production"
// //     ? "https://jrise-backeend-main.azurewebsites.net"
// //     : "http://localhost:3002";

// console.log("🚀 ~ file: user.api.js:2 ~ BaseUrl:", BaseUrl);

let BaseUrl = "https://www.risealliance.in:3002";
// let BaseUrl = "http://localhost:3001";
// let BaseUrl = `http://jrise-vm.centralindia.cloudapp.azure.com`;

export { BaseUrl };

export const SignUpRoutes = `${BaseUrl}/auth/signup`; // Note the use of ${}
export const getShaktiActiveProductsRoutes = `${BaseUrl}/products/get/getShaktiActiveProducts`; // Note the use of ${}
export const SignInRoutes = `${BaseUrl}/auth/login`; // Note the use of ${}
export const ValidateTokenRoutes = `${BaseUrl}/auth/validateToken`; // Note the use of ${}

export const OrderHistoryContextRoutes = {
  getUserFiles: (userId) => `${BaseUrl}/api/user/${userId}/files`,
};

export const OrderDownloadRoutes = {
  OrderDownload: (fileId) =>
    `${BaseUrl}/tax-invoices/generateTaxPDFInvoice/${fileId}`,
};

export const RefreshFilesRoutes = {
  RefreshFiles: (userId) => `${BaseUrl}/api/orderFormsAdmin/?userId=${userId}`,
};

// ANCHOR - MFI CRUD
// SECTION - Create
export const createMfiRoutes = `${BaseUrl}/mfi/createMfi`;

// SECTION - Read
export const GetAllMfiRoutes = `${BaseUrl}/mfi/getAllMfi`;

// SECTION - Refresh Get
export const GetRefreshAllMfiRoutes = `${BaseUrl}/mfi/getAllMfi`;

// SECTION - Update
export const UpdateMfiRoutes = {
  UpdateMfi: (uniqueId) => `${BaseUrl}/mfi/updateMfi/${uniqueId}`,
};

// SECTION - getMfiByUserId
export const getMfiByUserIdRoutes = {
  getMfiByUserId: (uniqueId) =>
    `${BaseUrl}/mfi/getMfiByUserId/?uniqueId=${uniqueId}`,
};

// SECTION - Delete
export const DeleteMfiRoutes = `${BaseUrl}/mfi/deleteMfi`;

export const getClientBillingDataAllRoutes = {
  getClientBillingDataAll: (userId) =>
    `${BaseUrl}/registration/getClientDataByUserId/${userId}`,
};

export const GetClientBillingDataRoutes = {
  BillingData: (id) => `${BaseUrl}/api/user/${id}/files`,
};

export const GenerateInvoiceRoutes = {
  GenerateInvoice: (fileId) =>
    `${BaseUrl}/tax-invoices/generateTaxPDFInvoice/${fileId}`,
};

export const CreateSaleOrderRoutes = `${BaseUrl}/api/orderForms`;
// export const GetSalesOrderRoutes = `${BaseUrl}/api/orderForms`;
export const GetSalesOrderRoutes = {
  GetSalesOrder: (userId) =>
    `${BaseUrl}/tax-invoices/getDelhiveryChallan/${userId}`,
};
export const RefreshFilesFormOrderRoutes = {
  RefreshFilesFormOrder: (userId) => `${BaseUrl}/tax-invoices/list/${userId}`,
};
export const updateSaleOrderRoutes = {
  UpdateSaleOrder: (id) => `${BaseUrl}/api/orderForms/${id}`,
};

export const softDeleteSaleOrderRoutes = {
  SoftDeleteSaleOrder: (id) => `${BaseUrl}/api/orderForms/${id}`,
};

//ANCHOR - DELIVERY CHALLAN CRUD
export const GetDeliveryChallanRoutes = {
  GetDeliveryChallan: (userId) => `${BaseUrl}/tax-invoices/list/${userId}`,
};
export const getStaticDataRoutes = `${BaseUrl}/api/staticData`;

// ANCHOR ADMIN ROUTES
// {{baseURL}}/auth/users?userId=true

export const getAllUsersRoutes = {
  GetAllUsers: (userId) => `${BaseUrl}/auth/getAllUsers`,
};

export const updateAllUsersRoutes = {
  UpdateAllUsers: (uniqueId, userId) =>
    `${BaseUrl}/auth/users/${uniqueId}/?userId=${userId}`,
};

export const GetSalesOrderAllRoutes = {
  GetSalesOrderAll: (userId) =>
    `${BaseUrl}/api/orderFormsAdmin/?userId=${userId}`,
};

export const OrderHistoryAdminRoutes = {
  orderHistoryAdmin: (userId) =>
    `${BaseUrl}/api/user/allFiles/?userId=${userId}`,
};

//ANCHOR - Create Products
export const CreateProductsAdminRoutes = {
  ProductsAdminRoutes: (userId) => `${BaseUrl}/products/?userId=${userId}`,
};

//ANCHOR - Get All Products
export const GetAllProductsAdminRoutes = `${BaseUrl}/products/getAllProducts`;

//ANCHOR - Get Active Products
// export const GetActiveProductsAdminRoutes = `${BaseUrl}/products/getActiveProducts`;
export const GetActiveProductsAdminRoutes = `${BaseUrl}/products/get/getShaktiActiveProducts`;

//ANCHOR - Get Deleted Products
export const GetDeletedProductsAdminRoutes = `${BaseUrl}/products/getDeletedProducts`;

//ANCHOR - Update Products
export const updateAllProductsRoutes = {
  updateAllProducts: (id, userId) =>
    `${BaseUrl}/products/${id}/?userId=${userId}`,
};
//ANCHOR - hardDelete Products
export const deleteProductsRoutes = {
  deleteProducts: (id) => `${BaseUrl}/products/hardDelete/${id}`,
};

export const GetProductsByIdRoute = {
  GetProductsById: (id) => `${BaseUrl}/products/${id}`,
};

export const createProductsExcelRoutes = `${BaseUrl}/products/createProductDataFromExcel`;

// ANCHOR - Home Page Admin Panel

// ANCHOR - Banner Home Page
// /home/banner-texts get
export const GetBannerHomePageRoutes = `${BaseUrl}/home/banner-texts`;
// /home/banner-texts update
export const UpdateBannerHomePageRoutes = {
  UpdateBannerHomePage: (id) => `${BaseUrl}/home/banner-texts/${id}`,
};
// /home/banner-texts delete
export const DeleteBannerHomePageRoutes = {
  DeleteBannerHomePage: (id) => `${BaseUrl}/home/banner-texts/${id}`,
};
// /home/banner-texts create
export const CreateBannerHomePageRoutes = `${BaseUrl}/home/banner-texts`;

// ANCHOR - Brands Home Page
//  get
export const GetBrandsHomePageRoutes = `${BaseUrl}/brandPartners/getAllBrandPartners`;
//  create
export const CreateBrandsHomePageRoutes = `${BaseUrl}/brandPartners/createBrandPartner`;

//  update
export const UpdateBrandsHomePageRoutes = {
  UpdateBrandsHomePage: (id) =>
    `${BaseUrl}/brandPartners/updateBrandPartner/${id}`,
};

//  update
export const DeleteBrandsHomePageRoutes = {
  DeleteBrandsHomePage: (id) =>
    `${BaseUrl}/brandPartners/softDeleteBrandPartner/${id}`,
};

// ANCHOR -  Warehouse
// ANCHOR - - GetWarehouseDataRoutes userId specific
export const GetWarehouseDataRoutes = {
  GetWarehouseData: (userId) =>
    `${BaseUrl}/warehouse/getWarehousesByUser/user/${userId}`,
};

// create warehouse userId specific
export const CreateWarehouseDataRoutes = `${BaseUrl}/warehouse/createWarehouse`;

// UpdateWarehouseDataRoutes userId specific
export const UpdateWarehouseDataRoutes = {
  UpdateWarehouseData: (warehouseId) =>
    `${BaseUrl}/warehouse/updateWarehouse/${warehouseId}`,
};

// ANCHOR - - Warehouse inward routes
export const GetWarehouseInwardDataRoutes = {
  GetWarehouseInwardData: (userId) =>
    `${BaseUrl}/wareHouseInward/getWareHouseInwardByUserId/${userId}`,
};
export const GetAllWarehouseDataRoutes = `${BaseUrl}/warehouse/getAllWarehouses`;

export const CreateWarehouseInwardDataRoutes = `${BaseUrl}/wareHouseInward/createWareHouseInward`;

export const UpdateWarehouseInwardDataRoutes = {
  UpdateWarehouseInwardData: (warehouseId) =>
    `${BaseUrl}/wareHouseInward/updateWareHouseInward/${warehouseId}`,
};

// ANCHOR - Tax Invoice Upload order form crud

// Create Order
export const createTaxInvoiceRoutes = `${BaseUrl}/tax-invoices/create`;
export const GetTaxInvoiceExcelDummyRoutes = `${BaseUrl}/tax-invoices/download-excel`;
export const GetShipToExcelDummyRoutes = `${BaseUrl}/tax-invoices/downloadExcelShipTo`;
export const updateTaxInvoiceApi = {
  updateTaxInvoiceData: (invoiceId) =>
    `${BaseUrl}/tax-invoices/updateTaxInvoice/${invoiceId}`,
};
export const GenerateTaxInvoiceRoutes = {
  GenerateTaxInvoice: (fileId) =>
    `${BaseUrl}/tax-invoices/generateTaxPDFInvoice/${fileId}`,
};

// ANCHOR - Purchase order form crud
// Create Order
export const createPurchaseOrderInvoiceRoutes = `${BaseUrl}/purchase-invoices/create`;

export const getPurchaseOrderInvoiceRoutes = {
  PurchaseOrderInvoiceRoutes: (userId) =>
    `${BaseUrl}/purchase-invoices/list/${userId}`,
};

export const getRefreshPurchaseOrderInvoiceRoutes = {
  PurchaseOrderInvoiceRoutes: (userId) =>
    `${BaseUrl}/purchase-invoices/list/${userId}`,
};

export const GeneratePurchaseOrderInvoiceRoutes = {
  PurchaseOrderInvoiceRoutes: (fileId) =>
    `${BaseUrl}/purchase-invoices/generatePurchaseOrder/${fileId}`,
};

// ANCHOR - DELIVERY CHALLAN
// ANCHOR - Create DELIVERY CHALLAN
export const createDeliveryChallanRoute = `${BaseUrl}/tax-invoices/createDeliveryChallan`;

// ANCHOR -  GET DELIVERY CHALLAN LIST
export const DeliveryChallanContextRoutes = {
  getDeliveryChallan: (userId) =>
    `${BaseUrl}/tax-invoices/getDelhiveryChallan/${userId}`,
};

// ANCHOR -  REFRESH DELIVERY CHALLAN LIST
export const RefreshFilesDeliveryChallanRoutes = {
  RefreshFilesFormOrder: (userId) =>
    `${BaseUrl}/tax-invoices/getDelhiveryChallan/${userId}`,
};

// ANCHOR -  update DELIVERY CHALLAN LIST
export const updateDeliveryChallanRoutes = {
  DeliveryChallanRoutes: (invoiceId) =>
    `${BaseUrl}/tax-invoices/updateDelhiveryChallan/${invoiceId}`,
};

// ANCHOR -  Generate DELIVERY CHALLAN LIST
export const GenerateDeliveryChallanRoutes = {
  GenerateDeliveryChallan: (fileId) =>
    `${BaseUrl}/tax-invoices/generateDelhiveryChallan/${fileId}`,
};

// ANCHOR - Get Excel Delivery Challan Routes
export const GetExcelDeliveryChallanRoutes = `${BaseUrl}/tax-invoices/downloadDelhiveryChallanExcelFormat`;

// ANCHOR - Delete Delivery Challan Routes
export const DeleteDeliveryChallanRoutes = {
  DeleteDeliveryChallan: (id) =>
    `${BaseUrl}/tax-invoices/deleteDeliveryChallan/${id}`,
};

// ANCHOR - Customer Invoices midland
// ANCHOR - Create Customer Invoices midland
export const createCustomerInvoicesRoute = `${BaseUrl}/tax-invoices/createTaxInvoiceMidland`;

// ANCHOR - Get Excel Customer Invoices midland
export const GetCustomerInvoicesRoutes = `${BaseUrl}/tax-invoices/downloadCustomerInvoiceExcelFormat`;

// ANCHOR -  get Customer Invoices  LIST
export const getCustomerInvoicesRoutes = {
  getCustomerInvoices: (userId) =>
    `${BaseUrl}/tax-invoices/getTaxInvoicesMidlands/${userId}`,
};

// ANCHOR -  update Customer Invoices  LIST
export const updateCustomerInvoicesRoutes = {
  updateCustomerInvoices: (userId) =>
    `${BaseUrl}/tax-invoices/getTaxInvoicesMidlands/${userId}`,
};
// ANCHOR -  get Customer Invoices  pdf
export const getCustomerInvoicesPdfRoutes = {
  getCustomerInvoicesPdf: (invoiceId) =>
    `${BaseUrl}/tax-invoices/generateTaxInvoiceMidlandIND/${invoiceId}`,
};

// ANCHOR - SHAKTI ADMIN PANEL WEBSITE
export const shaktiBulkProductUpdateDownloadRoutes = `${BaseUrl}/products/post/shaktiBulkProductUpdateDownload`;
export const shaktiBulkProductUpdateRoutes = `${BaseUrl}/products/patch/shaktiBulkProductUpdate`;
export const shaktiBulkProductCreateRoutes = `${BaseUrl}/products/create/shaktiBulkProductCreate`;
export const shaktiBulkProductCreateTemplateRoutes = `${BaseUrl}/products/download/shaktiBulkProductCreateTemplate`;

// GENERAL TAX INVOICE
// ANCHOR -  get Customer Invoices  LIST
export const getGeneralTaxInvoiceRoutes = `${BaseUrl}/tax-invoices/getTaxInvoicesAll`;

export const getGeneralTaxInvoicePdfRoutes = {
  getGeneralTaxInvoicePdf: (invoiceId) =>
    `${BaseUrl}/tax-invoices/generateTaxPDFInvoice/${invoiceId}`,
};
