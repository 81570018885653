import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../contexts/UserProviderMongo.jsx";
import "./auth.css";

function Auth() {
  const { signup, signin } = useAuth();
  const [isSignUp, setIsSignUp] = useState(false);

  const [signUpData, setSignUpData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  const [signInData, setSignInData] = useState({
    email: "",
    password: "",
  });

  const handleSignUpChange = (e) => {
    const { name, value } = e.target;
    setSignUpData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignInChange = (e) => {
    const { name, value } = e.target;
    setSignInData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    try {
      await signup(signUpData);
      setSignUpData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        password: "",
      });
      toast.success("Sign up successful!");
    } catch (error) {
      console.error(error);
      toast.error("Sign up failed!");
    }
  };

  const handleSignInSubmit = async (e) => {
    e.preventDefault();
    try {
      await signin(signInData);
      setSignInData({
        email: "",
        password: "",
      });
      // toast.success("Sign in successful!");
    } catch (error) {
      console.error(error);
      toast.error("Sign in failed!");
    }
  };

  return (
    <div className="auth-page">
      <div className="auth-container">
        {/* Left Section */}
        <div className="form-section-auth">
          <h1>{isSignUp ? "Welcome to Jrise!" : "Welcome Back!"}</h1>
          <form
            onSubmit={isSignUp ? handleSignUpSubmit : handleSignInSubmit}
            className="auth-form"
          >
            {isSignUp && (
              <>
                <div className="form-group">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={signUpData.firstName}
                    onChange={handleSignUpChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={signUpData.lastName}
                    onChange={handleSignUpChange}
                    required
                  />
                </div>
              </>
            )}
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={isSignUp ? signUpData.email : signInData.email}
                onChange={isSignUp ? handleSignUpChange : handleSignInChange}
                required
              />
            </div>
            {isSignUp && (
              <div className="form-group">
                <input
                  type="number"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={signUpData.phoneNumber}
                  onChange={handleSignUpChange}
                  required
                />
              </div>
            )}
            <div className="form-group">
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={isSignUp ? signUpData.password : signInData.password}
                onChange={isSignUp ? handleSignUpChange : handleSignInChange}
                required
              />
            </div>
            <button type="submit" className="auth-button">
              {isSignUp ? "Sign Up" : "Log In"}
            </button>
          </form>
          <p>
            {isSignUp
              ? "Already have an account?"
              : "Don’t have an account yet?"}{" "}
            <span
              className="switch-link"
              onClick={() => setIsSignUp(!isSignUp)}
            >
              {isSignUp ? "Log In" : "Sign Up"}
            </span>
          </p>
        </div>

        {/* Right Section */}
        <div className="info-section">
          <div className="info-overlay">
            <h2>{isSignUp ? "Welcome Back!" : "Hello, Friend!"}</h2>
            <p>
              {isSignUp
                ? "To keep connected with us, please log in with your personal info."
                : "Enter your details and start your journey with us."}
            </p>
            <button
              className="auth-button ghost"
              onClick={() => setIsSignUp(!isSignUp)}
            >
              {isSignUp ? "Log In" : "Sign Up"}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Auth;
