import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useUser } from "../../../../contexts/adminPanel.context.jsx";
import "./productsAdmin.css";
import LoadingOverlay from "../../../user-profile/order-history/LoadingOverlay.jsx";
import DeleteConfirmationModal from "../../../success-modal/deleteModal.js";
import PaginationComponent from "../../../pagination/PaginationComponent.jsx";
import Spinner from "react-bootstrap/esm/Spinner.js";
import EditProductsAdmin from "./editProductsAdmin.component.jsx";
import DateDisplay from "./DateDisplay.jsx";
import DeletedProductsAdmin from "./deletedProductsAdmin.jsx";
// import "tippy.js/dist/tippy.css"; // Import the CSS
import { Tooltip } from "react-tippy";
import AddIcon from "@mui/icons-material/AddCircleOutline"; // Plus icon
import CreateProductAdmin from "./createProducts.jsx";
import UploadProduct from "./uploadProduct.jsx";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function ProductsAdmin() {
  const { getActiveProductsAdmin } = useUser();
  console.log(
    "🚀 ~ file: productsAdmin.component.jsx:23 ~ ProductsAdmin ~ getActiveProductsAdmin:",
    getActiveProductsAdmin
  );
  const { refreshFilesGetProductsAdmin } = useUser();
  const { DeleteProductsWithAdminRole } = useUser();

  const pageSize = 10; // Number of rows per page

  const [getActiveProductsAdminPage, setgetActiveProductsAdminPage] =
    useState(null);
  const [currentPageForm, setCurrentPageForm] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [createProducts, setCreateProducts] = useState(null);
  const [uploadProduct, setUploadProduct] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUploadProductModal, setUploadProductModal] = useState(false);
  const [deleteFile, setDeleteFile] = useState(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  console.log(getActiveProductsAdminPage, "getActiveProductsAdminPage");
  //ANCHOR <!---- For Excel Order ----!>
  useEffect(() => {
    const getActiveProductsAdminMap = getActiveProductsAdmin?.data;
    console.log(
      "🚀 ~ file: order-history.jsx:57 ~ useEffect ~ files:",
      getActiveProductsAdminMap
    );

    if (getActiveProductsAdminMap) {
      // If getSaleOrderAllFormMap is not null or undefined, set it in the state
      setgetActiveProductsAdminPage(getActiveProductsAdminMap);
    }
  }, [getActiveProductsAdmin]);

  const handleEditClick = (file) => {
    setEditFile(file);
    setShowEditModal(true);
  };

  const handleCreateClick = () => {
    setCreateProducts();
    setShowCreateModal(true);
  };

  const handleUploadProductExcel = () => {
    setUploadProduct();
    setUploadProductModal(true);
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };
  const handleUploadProductModalClose = () => {
    setUploadProductModal(false);
  };

  const handleDeleteClick = (file) => {
    setDeleteFile(file);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async (file) => {
    if (file && file._id) {
      const uid = file._id;
      try {
        await DeleteProductsWithAdminRole(uid);
        setIsDeleteModalOpen(false);
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error("Invalid or missing _id in file object:", file);
    }
  };

  const handleCancelDelete = () => {
    // Cancel the delete operation and close the modal
    setIsDeleteModalOpen(false);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      await refreshFilesGetProductsAdmin();
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  if (isLoading) {
    return (
      <div>
        <h1>Refreshing Products</h1>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (
    !getActiveProductsAdmin?.data ||
    !getActiveProductsAdmin?.data?.length === 0 ||
    !getActiveProductsAdmin == null
  ) {
    return (
      <div>
        <h1>User Files</h1>
        <Button onClick={handleRefresh}>Refresh Data</Button>{" "}
        <p>No files found for the user.</p>
      </div>
    );
  }

  // For Form Order
  // const startIndexForm = (currentPageForm - 1) * pageSize;
  // const endIndexForm = Math.min(
  //   startIndexForm + pageSize,
  //   getActiveProductsAdminPage?.length
  // );

  // // Check if getActiveProductsAdmin.data is not null before mapping
  // const formUploadOrder = Array.isArray(getActiveProductsAdminPage)
  //   ? getActiveProductsAdminPage?.slice(startIndexForm, endIndexForm)
  //   : [];

  // const totalPagesForm = Math.ceil(
  //   Array.isArray(getActiveProductsAdminPage)
  //     ? getActiveProductsAdminPage?.length
  //     : 0 / pageSize
  // );

  // For Form Order
  const startIndexForm = (currentPageForm - 1) * pageSize;
  const endIndexForm = Math.min(
    startIndexForm + pageSize,
    getActiveProductsAdminPage?.length
  );
  const formUploadOrder = getActiveProductsAdminPage?.slice(
    startIndexForm,
    endIndexForm
  );
  const totalPagesForm = Math.ceil(
    getActiveProductsAdminPage?.length / pageSize
  );

  console.log(
    "🚀 ~ file: productsAdmin.component.jsx:140 ~ ProductsAdmin ~ totalPagesForm:",
    formUploadOrder
  );

  const handlePageChangeForm = (page) => {
    setCurrentPageForm(page);
  };

  // ANCHOR - FIELDS
  const fields = formUploadOrder?.map((file) => {
    return [
      { name: "Brand", value: file?.brand },
      { name: "Category", value: file?.category },
      { name: "Price", value: `${file?.price}` },
      // Add more fields as needed
    ];
  });

  return (
    <div className="order-history-body">
      <div className="form-upload-order-container">
        {" "}
        <h1>Active Products</h1>
        <Button className="order-history-Refresh" onClick={handleRefresh}>
          Refresh Data
        </Button>
        <button
          onClick={() => handleCreateClick()}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            marginLeft: "10px",
          }}
        >
          <AddIcon color="primary" />
        </button>
        <button
          onClick={() => handleUploadProductExcel()}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            marginLeft: "10px",
          }}
        >
          <CloudUploadIcon color="primary" />
        </button>
        <Table striped bordered hover className="custom-table">
          <thead>
            <tr>
              <th>image</th>
              <th>brand</th>
              <th>category</th>
              <th>name</th>
              <th>sku code</th>
              <th>Model No</th>
              <th>description</th>
              <th>description Two</th>
              <th>description Three</th>
              <th>HSN CODE</th>
              <th>Product Weight(kg)</th>
              <th>length</th>
              <th>breadth</th>
              <th>height</th>
              <th>price</th>
              <th>countInStock</th>
              <th>Status</th>
              <th></th>
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody>
            {formUploadOrder?.map((file) => (
              <tr id="limited-row" key={file._id}>
                {/* // Inside the tbody where you're mapping over formUploadOrder */}
                <td className="image-slider">
                  <div className="image-slider-container">
                    <Carousel>
                      {file?.image?.map((image, index) => (
                        <Carousel.Item key={index}>
                          <img
                            className="d-block w-100"
                            src={image?.imgUrl}
                            alt={`Product ${index + 1}`}
                            onError={(e) => {
                              e.target.src = "/404.png";
                            }}
                            style={{
                              maxHeight: "150px",
                              maxWidth: "150px",
                              minHeight: "150px",
                              minWidth: "150px",
                              width: "auto",
                              height: "auto",
                            }}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                </td>
                <td
                  className=""
                  data-tip={file?.brand}
                  data-for="brand-tooltip"
                >
                  {file?.brand}
                </td>
                {/* <td className="">{file?.brand}</td> */}
                <td className="">{file?.category ?? `NAN`}</td>
                <td className="">{file?.name ?? `NAN`}</td>
                <td className="">{file?.skuCode ?? `NAN`}</td>
                <td className="">{file?.modelNo ?? `NAN`}</td>
                {/* <td className="">{file?.description}</td> */}
                <td id="limited-row">
                  <Tooltip
                    title={file?.description ?? `NAN`}
                    position="top"
                    trigger="mouseenter"
                    animation="shift"
                  >
                    <span>{file?.description ?? `NAN`}</span>
                  </Tooltip>
                </td>
                <td id="limited-row">
                  <Tooltip
                    title={file?.descriptionTwo ?? `NAN`}
                    position="top"
                    trigger="mouseenter"
                    animation="shift"
                  >
                    <span>
                      {file?.descriptionTwo === ""
                        ? `NAN`
                        : file?.descriptionTwo}
                    </span>
                  </Tooltip>
                </td>
                <td id="limited-row">
                  <Tooltip
                    title={file?.descriptionThree ?? `NAN`}
                    position="top"
                    trigger="mouseenter"
                    animation="shift"
                  >
                    <span>
                      {file?.descriptionThree === ""
                        ? `NAN`
                        : file?.descriptionThree}
                    </span>
                  </Tooltip>
                </td>
                <td className="">{file?.hsnCode}</td>
                <td className="">{file?.productWeight ?? `NAN`}</td>
                <td className="">{file?.productDimensions?.length ?? `NAN`}</td>
                <td className="">
                  {file?.productDimensions?.breadth ?? `NAN`}
                </td>
                <td className="">{file?.productDimensions?.height ?? `NAN`}</td>
                <td className="">{file?.price ?? `NAN`}</td>
                <td className="">{file?.countInStock ?? `NAN`}</td>
                <td className="">{!file?.deleted ? "Active" : "InActive"}</td>
                <td className="">
                  <button
                    onClick={() => handleEditClick(file)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <EditIcon color="primary" />
                  </button>
                </td>
                <td className="">
                  <button
                    onClick={() => handleDeleteClick(file)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <DeleteIcon color="error" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <PaginationComponent
          currentPage={currentPageForm}
          totalPages={totalPagesForm}
          onPageChange={handlePageChangeForm}
        />
        {/* {!isLoading && <LoadingOverlay />} */}
      </div>
      <DeletedProductsAdmin />
      {showEditModal && (
        <EditProductsAdmin
          isOpen={showEditModal}
          onRequestClose={handleEditModalClose}
          file={editFile}
        />
      )}
      {showCreateModal && (
        <CreateProductAdmin
          isOpen={showCreateModal}
          onRequestClose={handleCreateModalClose}
          file={createProducts}
        />
      )}
      {showUploadProductModal && (
        <UploadProduct
          isOpen={showUploadProductModal}
          onRequestClose={handleUploadProductModalClose}
          file={uploadProduct}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onRequestClose={handleCancelDelete}
          onConfirmDelete={handleConfirmDelete}
          file={deleteFile}
          fields={fields}
        />
      )}
    </div>
  );
}

export default ProductsAdmin;
