import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Divider } from "@mui/material";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import Modal from "react-modal";
import SubmitButton from "../../bulk-order/submit.btn.component.jsx";
import ShipToButton from "../../bulk-order/shipToTemplateBtn.jsx";
import { WarehouseContext } from "../../../contexts/warehouse.context.jsx";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";
import FileButtons from "../../bulk-order/fileButtons.jsx";
import { useDeliveryChallanMongo } from "../../../contexts/deliveryChallanContext.jsx";
import DownloadIcon from "@mui/icons-material/Download";
import { GetExcelDeliveryChallanRoutes } from "../../../api/user.api.js";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px", // Increase padding for better spacing
    maxWidth: "800px", // Set a maximum width for the modal
    width: "100%", // Make the modal responsive
    maxHeight: "80vh", // Set a maximum height for the modal (80% of the viewport height)
    overflowY: "auto", // Enable vertical scrolling if the content exceeds the height
    margin: "10px",
  },
};

Modal.setAppElement("#root");
const CreateCustomerInvoicesMidland = ({ isOpen, onRequestClose }) => {
  const { CreateCustInvMidlandContext } = useDeliveryChallanMongo();
  const { CustInvXlsxTemplateContext } = useDeliveryChallanMongo();

  const { user } = useAuth();
  const { getSalesOrder } = useAuth([]);
  const { getAllWarehouseContent } = WarehouseContext([]);
  console.log(
    "🚀 ~ CreateCustomerInvoicesMidland ~ getAllWarehouseContent:",
    getAllWarehouseContent
  );
  const { getStaticDataS } = useAuth([]);

  const userId = user?.userId;

  //For Upload of template
  const [uploadOrder, setUploadOrder] = useState(null);
  const [uploadOrderResData, setUploadOrderResData] = useState([]);
  const [uploadResCheck, setUploadResCheck] = useState(null);
  //UseState for static data
  const [getStatesDropDown, setGetStatesDropDown] = useState([]);

  useEffect(() => {
    if (getStaticDataS) {
      setGetStatesDropDown(getStaticDataS?.placeOfSupply);
    } else {
      false;
    }
  }, []);

  const data = uploadOrderResData?.data?.map((x) => x);

  const handleTemplateDownload = async () => {
    try {
      // Call the CustInvXlsxTemplateContext function to fetch the template
      await CustInvXlsxTemplateContext();

      // Show success message after downloading the template
      toast.success("Template Downloaded Successfully");
    } catch (error) {
      // Log and handle any errors that occur during the process
      console.error("Error during Template download:", error);
      // Show error message if download fails
      toast.error("Template Download Failed");
    }
  };

  const handleShipToTemplateDownload = async (fileId) => {
    try {
      const response = await fetch(GetShipToExcelDummyRoutes, {
        method: "GET",
      });

      if (response.status === 200) {
        const contentDisposition = response.headers.get("Content-Disposition");

        const filename = filenameMatch
          ? filenameMatch[1]
          : "ShipToTemplateXlsx";

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        if (response.status === 200 || response.status === 201) {
          toast.success("Template Downloaded Successfully");
        } else {
          toast.error("Template Download Failed");
        }
      } else {
        console.error("Template Download Failed");
      }
    } catch (error) {
      console.error("Error during Template download:", error);
    }
  };

  // Form state
  const [formData, setFormData] = useState({
    productFile: null,
    shipToFile: null,
    shipFrom: { name: "", shipFromId: "", shipFromPlaceReq: "" },
    invoiceDate: "",
    // shipTo: [{ name: "", address: "", placeOfSupplyShip: "" }],
    userId: 4,
    PaymentTerms: "",
  });

  const [productMapping, setProductMapping] = useState({});

  useEffect(() => {
    // console.log("formData changed:", formData);
  }, [formData]);

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      // Split the name into an array to access nested properties
      const nameArray = name.split(".");

      // Handle nested properties
      if (nameArray.length === 2) {
        return {
          ...prevData,
          [nameArray[0]]: {
            ...prevData[nameArray[0]],
            [nameArray[1]]: value,
          },
        };
      }

      // Handle non-nested properties
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setFormData((prevData) => ({
      ...prevData,
      productFile: file,
    }));
  };

  // Handle file input change
  const handleShipToFileChange = (e) => {
    const file = e.target.files[0];

    setFormData((prevData) => ({
      ...prevData,
      shipToFile: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create form data
    const data = new FormData();

    // Append form fields
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "shipFrom") {
        // Handle shipFrom and billTo as objects
        Object.entries(value).forEach(([subKey, subValue]) => {
          data.append(`${key}[${subKey}]`, subValue);
        });
      } else {
        data.append(key, value);
      }
    });

    try {
      // Make Axios call
      await CreateCustInvMidlandContext(data);
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  useEffect(() => {
    // Set formData.invoiceDate to today's date when the component mounts
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      invoiceDate: formattedDate,
    }));
  }, []); // Run this effect only once, when the component mounts

  return (
    <div>
      <div className="file-buttons">
        <div className="form-container">
          <form onSubmit={handleSubmit} className="invoice-form">
            <div className="tax-invoice-container-bulk-upload">
              {/* <h2 className="text-left font-bold">Tax Invoice</h2> */}
              <h2 className="text-center">
                Create Customer Invoices for Midland
              </h2>

              <div className="row mt-10">
                <div className="col-sm-4">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="invoiceDate">
                      Invoice Date
                    </label>
                    <input
                      type="date"
                      id="invoiceDate"
                      name="invoiceDate"
                      value={formData.invoiceDate}
                      onChange={handleInputChange}
                      style={{
                        lineHeight: "24px",
                        borderRadius: "10px",
                        background: "#F1F3F4",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-10">
                <div className="col-sm-4">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="shipFromName">
                      Ship From Name
                    </label>
                    <input
                      type="text"
                      id="shipFromName"
                      name="shipFrom.name"
                      value={formData.shipFrom?.name}
                      onChange={handleInputChange}
                      style={{
                        lineHeight: "24px",
                        borderRadius: "10px",
                        background: "#F1F3F4",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="shipFromAddress">
                      Ship From Address
                    </label>
                    <FormControl fullWidth>
                      <InputLabel id="warehouseLabel">
                        Select Warehouse
                      </InputLabel>
                      <Select
                        labelId="warehouseLabel"
                        id="name"
                        name="shipFrom.shipFromId"
                        value={formData?.shipFrom?.shipFromId}
                        onChange={handleInputChange}
                        style={{
                          borderRadius: "10px",
                          background: "#F1F3F4",
                        }}
                      >
                        <MenuItem value="">
                          <em>Select Warehouse</em>
                        </MenuItem>
                        {getAllWarehouseContent?.data.map((warehouse) =>
                          warehouse?.name.map((item) => (
                            <MenuItem key={item._id} value={item?._id}>
                              <div>{item?.value}</div>
                            </MenuItem>
                          ))
                        )}
                        {/* 
                        {console.log(
                          "getAllWarehouseContent.map:",
                          getAllWarehouseContent?.data?.map(
                            (item) => item?.name
                          )
                        )} */}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-sm-4">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="shipFromAddress">
                      Ship From State
                    </label>
                    <FormControl fullWidth>
                      <InputLabel id="warehouseLabel">Select State</InputLabel>
                      <Select
                        labelId="warehouseLabel"
                        id="name"
                        name="shipFrom.shipFromPlaceReq"
                        value={formData?.shipFrom?.shipFromPlaceReq}
                        onChange={handleInputChange}
                        style={{
                          borderRadius: "10px",
                          background: "#F1F3F4",
                        }}
                      >
                        <MenuItem value="">
                          <em>Select State</em>
                        </MenuItem>
                        {getStatesDropDown.map((state, index) => (
                          <MenuItem key={index} value={state?.id}>
                            <div key={state?.id} value={state?.id}>
                              {state?.formattedCode}
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              {/* shipTo */}
              <div className="row mt-10">
                <div className="col-sm-6">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="productFile">
                      {" "}
                      Product File:
                    </label>
                    <input
                      type="file"
                      name="productFile"
                      onChange={handleFileChange}
                      // Remove the "value" attribute for file input
                      required
                      style={{
                        lineHeight: "24px",
                        borderRadius: "10px",
                        background: "#F1F3F4",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="shipToFile">
                      {" "}
                      Ship To File:
                    </label>
                    <input
                      type="file"
                      name="shipToFile"
                      onChange={handleShipToFileChange}
                      // Remove the "value" attribute for file input
                      required
                      style={{
                        lineHeight: "24px",
                        borderRadius: "10px",
                        background: "#F1F3F4",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "left",
                  width: "100%",
                }}
              >
                <div className="file-buttons-body">
                  <button id="download-button" onClick={handleTemplateDownload}>
                    Customer Invoice XLSX template <DownloadIcon />
                  </button>
                </div>
                <div style={{ marginLeft: "20px" }}>
                  {" "}
                  {/* Adjust the margin as needed */}
                  <SubmitButton handleSubmit={handleSubmit} />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="mt-10"></div>
        <Divider />
        {/* <OrderHistory className="mt-10" /> */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default CreateCustomerInvoicesMidland;
