import React, { useState, useEffect } from "react";
import { BaseUrl, getShaktiActiveProductsRoutes } from "../../api/user.api.js";
import axios from "axios";

const ShaktiProducts = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [brandFilter, setBrandFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Define the function inside useEffect to avoid async directly on useEffect
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${BaseUrl}/products/get/getShaktiActiveProducts`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true, // Include credentials for cross-origin requests
          }
        );

        if (response.status !== 200) {
          throw new Error(`Error: ${response.statusText}`);
        }

        // Handle the response data
        const data = response?.data;
        console.log("Fetched Products:", data);

        setProducts(data?.data || []);
        setFilteredProducts(data?.data || []);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching products:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProducts(); // Call the async function
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const handleFilterChange = () => {
    let filtered = products;

    if (brandFilter) {
      filtered = filtered.filter((product) => product?.brand === brandFilter);
    }
    if (categoryFilter) {
      filtered = filtered.filter(
        (product) => product?.category === categoryFilter
      );
    }
    setFilteredProducts(filtered);
  };

  useEffect(() => {
    handleFilterChange();
  }, [brandFilter, categoryFilter]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div style={{ fontFamily: "Arial, sans-serif" }}>
      {/* Filters Section */}
      <div
        style={{
          background: "#f5f5f5",
          padding: "10px 20px",
          display: "flex",
          gap: "20px",
          borderBottom: "1px solid #ddd",
          position: "sticky",
          top: "0",
          zIndex: "1000",
        }}
      >
        <h3 style={{ margin: "0" }}>Filters</h3>
        <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          Brand:
          <select
            value={brandFilter}
            onChange={(e) => setBrandFilter(e.target.value)}
            style={{
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          >
            <option value="">All</option>
            {[...new Set(products.map((p) => p.brand))].map((brand) => (
              <option key={brand} value={brand}>
                {brand}
              </option>
            ))}
          </select>
        </label>
        <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          Category:
          <select
            value={categoryFilter}
            onChange={(e) => setCategoryFilter(e.target.value)}
            style={{
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          >
            <option value="">All</option>
            {[...new Set(products.map((p) => p.category))].map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </label>
      </div>

      {/* Product List Section */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: "15px",
          padding: "20px",
        }}
      >
        {filteredProducts.length === 0 ? (
          <p>No products found.</p>
        ) : (
          filteredProducts.map((product) => (
            <div
              key={product._id}
              style={{
                background: "white",
                borderRadius: "10px",
                overflow: "hidden",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "350px", // Fixed card height
              }}
            >
              <img
                src={product.image[0]?.imgUrl}
                alt={product.name}
                style={{
                  width: "100%",
                  height: "150px",
                  objectFit: "contain",
                  background: "#f9f9f9",
                }}
              />
              <div style={{ padding: "10px", flex: "1" }}>
                <h4
                  title={product.name} // Tooltip displays full product name
                  style={{
                    fontSize: "16px",
                    margin: "0 0 5px 0",
                    height: "40px", // Fixed height for titles
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    cursor: "pointer", // Pointer to indicate tooltip
                  }}
                >
                  {product.name}
                </h4>

                {/* <p
                  style={{
                    fontSize: "14px",
                    color: "#555",
                    margin: "0 0 10px 0",
                    height: "40px", // Fixed height for descriptions
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {product.description}
                </p> */}
                <br />
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#000",
                    fontSize: "14px",
                    margin: "0",
                  }}
                >
                  Customer Price: ₹{product.CustomerPrice}{" "}
                  <span
                    style={{
                      textDecoration: "line-through",
                      color: "#999",
                      fontSize: "12px",
                      marginLeft: "10px",
                    }}
                  >
                    MRP: ₹{product.MRP}
                  </span>
                </p>
              </div>
              {/* <button
                disabled
                style={{
                  width: "100%",
                  padding: "10px 0",
                  background: "#28a745",
                  color: "white",
                  border: "none",
                  borderRadius: "0 0 10px 10px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Add to Cart
              </button> */}
            </div>
          ))
        )}
      </div>

      {/* Sticky Enquire Now Button
      <button
        disabled
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#28a745",
          color: "white",
          border: "none",
          padding: "15px 30px",
          cursor: "pointer",
          borderRadius: "5px",
          fontSize: "16px",
          boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
        }}
      >
        Enquire Now
      </button> */}
    </div>
  );
};

export default ShaktiProducts;
