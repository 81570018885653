import React, { useEffect, useState } from "react";
import { useProductContextMongo } from "../../../contexts/ProductContext.jsx";
import { Button, Table } from "react-bootstrap";
import EditShaktiProduct from "./editShaktiProduct.js";
import { BaseUrl } from "../../../api/user.api.js";
import axios from "axios"; // Import axios for making HTTP requests
import { toast } from "react-toastify";

const ShaktiProductPage = () => {
  const {
    products: contextProducts,
    fetchProducts,
    loading: contextLoading,
  } = useProductContextMongo();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // Fetch products from context and update local state
  useEffect(() => {
    const loadProducts = async () => {
      setLoading(true);
      await fetchProducts(); // Fetch products using context
    };

    loadProducts();
  }, []); // Only run when the component mounts (initial render)

  // Update products and loading state only when contextProducts or contextLoading change
  useEffect(() => {
    setProducts(contextProducts);
    setLoading(contextLoading);
  }, [contextProducts, contextLoading]);

  const handleEdit = (productId) => {
    const productToEdit = products?.data?.find(
      (product) => product?._id === productId
    );
    setSelectedProduct(productToEdit);
    setIsEditModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false); // Close the modal
    setSelectedProduct(null); // Clear selected product
  };

  const handleDelete = async (productId) => {
    console.log("🚀 ~ handleDelete ~ productId:", productId);
    const deleteUrl = `${BaseUrl}/products/delete/softDeleteProductShakti/${productId}/159`;

    try {
      // Send DELETE request
      const response = await axios.delete(deleteUrl);

      if (response.status === 200) {
        // Assuming successful deletion
        console.log("Product deleted:", response.data);

        // Show success toast
        toast.success("Product deleted successfully!");

        // Optionally, re-fetch products or update state to reflect the change
        fetchProducts(); // Call your fetchProducts function here to refresh the list
      } else {
        console.log("Error deleting product:", response);
        toast.error("Failed to delete product. Please try again.");
      }
    } catch (error) {
      console.error("Error while deleting product:", error);
      toast.error("An error occurred while deleting the product.");
    }
  };

  if (loading) {
    return <div>Loading products...</div>;
  }

  return (
    <div style={{ padding: "20px" }}>
      <h1>Active Products</h1>
      {products?.data?.length > 0 ? (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Name</th>
              <th>Brand</th>
              <th>Model No</th>
              <th>SKU Code</th>
              <th>Price (₹)</th>
              <th>MRP (₹)</th>
              <th>Stock</th>
              <th>GST (%)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products?.data?.map((product, index) => (
              <tr key={product._id}>
                <td>{index + 1}</td>
                <td>
                  <img
                    src={product.image[0]?.imgUrl || "placeholder.png"}
                    alt={product.name}
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                  />
                </td>
                <td>{product.name}</td>
                <td>{product.brand}</td>
                <td>{product.modelNo}</td>
                <td>{product.skuCode}</td>
                <td>₹{product.CustomerPrice}</td>
                <td>₹{product.MRP}</td>
                <td>{product.countInStock}</td>
                <td>{product.gstPercent}%</td>
                <td>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handleEdit(product._id)}
                    style={{ marginRight: "10px" }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(product._id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No active products found.</p>
      )}
      {/* Edit Modal */}
      {isEditModalOpen && selectedProduct && (
        <EditShaktiProduct
          isOpen={isEditModalOpen}
          onRequestClose={handleCloseModal}
          productId={selectedProduct._id}
          productData={selectedProduct}
          fetchProducts={fetchProducts}
        />
      )}
    </div>
  );
};

export default ShaktiProductPage;
